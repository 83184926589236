import fromPairs from 'lodash/fromPairs';
import keyBy from 'lodash/keyBy';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import toPairs from 'lodash/toPairs';
import uniqueId from 'lodash/uniqueId';
import values from 'lodash/values';
import {
  Price,
  ChargesRowData,
  Claim,
  ClaimSplit,
  ExtendedServiceCode,
  PatientInvoice,
  PatientStatementRequest,
} from 'pages/Dashboard/pages/Billing/types';
import {
  ChargesResponseDTO,
  PatientDTO,
  ClaimBundleDTO,
  VisitNoteBillingCodeDTO,
  SplitClaimsDTO,
  EOBWithInsurancePaymentRequestDTO,
  VisitNoteBillingAssessmentCodeDTO,
  ServiceCodeDetailsDTO,
  PatientStatementDTO,
  ChargeMasterDTO,
  CustomChargeMasterDTO,
  PatientStatementRequestDTO,
  WriteOffByEncounterDTO,
  WriteOffRequestDTO,
  WriteOffDTO,
  NdcCodeDTO,
} from 'dtos';
import {
  formatDateOnly,
} from 'utils/date';
import dayjs from 'utils/dayjs';
import {
  getDefaultIf,
  isEmptyString,
  setDefaultValueIf,
} from 'utils/misc';
import {
  AlertVariant,
} from 'pages/Dashboard/components/Alert';
import {
  normalizePhoneNumber,
} from 'pages/Dashboard/utils/helper';
import pickBy from 'lodash/pickBy';
import {
  withServiceCodesAllocation,
} from 'pages/Dashboard/pages/Billing/Payments/utils';
import {
  VisitNoteBillingCode,
} from 'views/EMR/components/ServiceLookup/type';
import map from 'lodash/map';
import {
  formatLinkedICDCodes,
} from 'pages/Dashboard/pages/Encounters/pages/VisitNote/utils/helper';
import compact from 'lodash/compact';

const paymentNotifications = Object.freeze([{
  variant: AlertVariant.ERROR,
  message: 'Payment Failed, please try again later',
},
{
  variant: AlertVariant.SUCCESS,
  message: 'Payment Received Successfully',
},
{
  variant: AlertVariant.INFO,
  message: 'Payment Cancelled',
},
]);

const achFields: readonly string[] = Object.freeze([
  'senderAccountNumber',
  'senderRoutingNumber',
  'receiverAccountNumber',
  'receiverRoutingNumber',
]);

export const paymentNotificationsByVariant = Object.freeze(keyBy(paymentNotifications, 'variant'));

function formatNdcCode(ndc?: NdcCodeDTO | null | string) {
  return typeof ndc === 'string' ? {
    convertedNdcPackageCode: ndc,
  } : ndc;
}

export function normalizeBillingCodes(
  billingCodes: VisitNoteBillingCodeDTO[],
  serviceCodeDefaults?: ServiceCodeDetailsDTO,
  assessmentCodes?: VisitNoteBillingAssessmentCodeDTO[],
): VisitNoteBillingCode[] {
  return (billingCodes ?? []).map((billingCode) => ({
    ...billingCode,
    serviceCodeDetails: {
      ...billingCode?.serviceCodeDetails,
      priorAuthCode:
        billingCode?.serviceCodeDetails?.priorAuthCode
        ?? serviceCodeDefaults?.priorAuthCode,
      pos:
        billingCode.serviceCodeDetails?.pos
        ?? serviceCodeDefaults?.pos,
      serviceDate: dayjs(
        billingCode?.serviceCodeDetails?.serviceDate
        ?? serviceCodeDefaults?.serviceDate,
      ),
      drugIdentification: {
        ...(billingCode?.serviceCodeDetails?.drugIdentification ?? {}),
        unitCode: {
          code: billingCode?.serviceCodeDetails?.drugIdentification?.unitCode
            ?? serviceCodeDefaults?.drugIdentification?.unitCode
            ?? '',
        },
      },
    },
    assessmentCodes: assessmentCodes?.map(({
      keyId, visitNoteBillingAsessmentCodeId, ...assessmentCodes
    }) => assessmentCodes),
    __id: billingCode?.visitNoteBillingCodeId?.toString() ?? uniqueId(billingCode?.code ?? ''),
  }));
}

// @TODO: consolidate format billing codes
export const serializeBillingCodesForClaim = (
  billingCodes: VisitNoteBillingCode[],
  assessmentCodes: VisitNoteBillingAssessmentCodeDTO[],
): VisitNoteBillingCodeDTO[] => {
  const orderIndexes = fromPairs(assessmentCodes.map(({ code }, index) => ([code, index])));
  const availableCodes = new Set(compact(map(assessmentCodes, 'code')));

  return billingCodes
    .map(({
      __id,
      linkedICDCodes,
      serviceCodeDetails,
      modifiers,
      assessmentCodes,
      ...item
    }: VisitNoteBillingCode) => ({
      ...item,
      linkedICDCodes: formatLinkedICDCodes(
        linkedICDCodes ?? [],
        orderIndexes,
        availableCodes,
      ),
      serviceCodeDetails: {
        ...(serviceCodeDetails ?? {}),
        serviceDate: formatDateOnly(serviceCodeDetails?.serviceDate ?? ''),
        drugIdentification: {
          ...serviceCodeDetails?.drugIdentification,
          ndc: formatNdcCode(
            serviceCodeDetails?.drugIdentification?.ndc,
          )?.convertedNdcPackageCode,
          unitCount: Number(serviceCodeDetails?.drugIdentification?.unitCount) === 0
            ? null : Number(serviceCodeDetails?.drugIdentification?.unitCount),
          unitCode: serviceCodeDetails?.drugIdentification?.unitCode?.code ?? '',
        },
      },
      modifiers: (modifiers ?? [])
        .map((modifier) => ({
          ...modifier,
          description: modifier.description ?? modifier.code,
        })),
    }));
};

const amountKeys: readonly string[] = Object.freeze([
  'allowedAmount',
  'insurancePaidAmount',
  'balance',
  'patientResponsibility',
  'billedAmount',
]);

export function normalizeCharges(
  { charges }: ChargesResponseDTO,
): ChargesRowData[] {
  return charges?.data?.map(({
    claims,
    patient,
    visitDateTime,
    encounterId,
    serviceCodes,
    isFinalized,
    reasonForVisit,
    billingReviewState,
    patientResponsibility,
    patientBalance,
    isOutOfPocket,
    customServiceCodes,
  }) => {
    const keyedSecondaryClaims = keyBy(
      (claims ?? []).filter(({ referencedPrimaryClaimId }) => !isNil(referencedPrimaryClaimId)),
      'referencedPrimaryClaimId',
    ) ?? {};

    const bill = {
      state: billingReviewState,
      serviceCodes: serviceCodes?.map(({
        modifiers, icdCodes, ...serviceCode
      }) => ({
        ...serviceCode,
        modifiers: (modifiers ?? []).map((modifier) => ({ code: modifier })),
        linkedICDCodes: (icdCodes ?? []).map((linkedICDCode) => ({ code: linkedICDCode })),
        serviceCodeDetails: {
          ...serviceCode,
          ...pick(serviceCode ?? [], amountKeys),
          primaryClaimId: serviceCode.primaryClaimId ?? 0,
        },
      })),
      customServiceCodes: customServiceCodes ?? [],
    };

    const codesByClaimIds: Record<number, ExtendedServiceCode[]> = fromPairs(
      claims?.map(({ claimId }) => [claimId ?? 0, []]),
    );

    (bill?.serviceCodes ?? []).forEach((code) => {
      codesByClaimIds[code.serviceCodeDetails?.primaryClaimId ?? 0]?.push(code);
    });

    return {
      patient: patient as PatientDTO,
      claims: (claims ?? []).map((claim) => ({
        ...claim,
        status: isOutOfPocket ? 'Out-of-pocket' : claim?.status,
      })),
      keyedSecondaryClaims,
      codesByClaimIds,
      bill,
      visitDateTime,
      encounterId,
      patientId: patient?.patientId,
      isFinalized,
      reasonForVisit,
      patientResponsibility,
      patientBalance,
      isOutOfPocket,
    };
  }) ?? [];
}

export function normalizeClaimForm({
  claim,
  visitNote,
  serviceCodeDefaults,
}: ClaimBundleDTO): Claim {
  return {
    visitNote,
    transmissions: [...(claim?.transmissions ?? [])].reverse(),
    assessmentCodes: visitNote?.bill?.assessmentCodes ?? [],
    billingCodes: normalizeBillingCodes(
      visitNote?.bill?.serviceCodes ?? [],
      serviceCodeDefaults as ServiceCodeDetailsDTO,
      visitNote?.bill?.assessmentCodes ?? [],
    ),
    ...pick(claim, ['claimId', 'patientId', 'encounterId', 'organizationId', 'externalId', 'totalAmountPaid']),
    dates: (
      claim?.details?.dates ?? []
    ).map(({ qualifier, date }) => ({
      item: { qualifier: qualifier ?? '' },
      date: dayjs(date),
    })),
    priorAuthCode: claim?.details?.priorAuthCode ?? '',
    ...pick(claim?.details ?? {}, [
      'autoAccident',
      'otherAccident',
      'originalReferenceNumber',
      'additionalInformation',
    ]),
    ...(claim?.details?.employment ?? {}),
    ...omit(claim?.context ?? {}, ['insuranceDetails', 'assignment', 'insurancePolicy', 'insuranceType']),
    ...(claim?.context?.insuranceDetails ?? {}),
    flag: claim?.notes?.flag ?? false,
    notesText: claim?.notes?.notesText ?? '',
    billingProvider: { id: claim?.context?.billingProviderUserId ?? 0, name: '', nameWithCredentials: '' },
    renderingProvider: { id: claim?.context?.renderingProviderUserId ?? 0, name: '', nameWithCredentials: '' },
    serviceFacility: { addressId: claim?.context?.serviceFacilityAddressId ?? 0 },
    insurance: { insuranceId: claim?.context?.insuranceDetails?.insuranceId ?? 0 },
    assignment: { code: claim?.context?.insuranceDetails?.assignment ?? '' },
    insurancePolicy: { code: claim?.context?.insuranceDetails?.insurancePolicy ?? '' },
    insuranceType: { code: claim?.context?.insuranceDetails?.insuranceType },
    resubmissionCode: { code: claim?.details?.resubmissionCode },
    referencedPrimaryClaimId: claim?.referencedPrimaryClaimId,
  };
}

export function serializeClaimForm({
  serviceFacility,
  referringProvider,
  supervisingProvider,
  assignment,
  insurance,
  insuranceType,
  insurancePolicy,
  billingProvider,
  renderingProvider,
  additionalInformation,
  dates,
  priorAuthCode,
  isRelatedToEmployment,
  autoAccident,
  otherAccident,
  resubmissionCode,
  originalReferenceNumber,
  assessmentCodes,
  billingCodes,
  flag,
  notesText,
}: Claim, originalData?: ClaimBundleDTO): ClaimBundleDTO {
  return {
    visitNote: {
      ...(originalData?.visitNote ?? {}),
      bill: {
        ...(originalData?.visitNote?.bill ?? {}),
        assessmentCodes,
        serviceCodes: serializeBillingCodesForClaim(billingCodes ?? [], assessmentCodes ?? []),
      },
    },
    claim: {
      ...(originalData?.claim ?? {}),
      context: {
        referringProvider: values(referringProvider).every(isEmptyString) ? undefined : {
          ...referringProvider,
          ...setDefaultValueIf(referringProvider ?? {}, null, isEmptyString),
        },
        supervisingProvider: values(supervisingProvider).every(isEmptyString) ? undefined : {
          ...supervisingProvider,
          ...setDefaultValueIf(supervisingProvider ?? {}, null, isEmptyString),
        },
        billingProviderUserId: billingProvider?.id === 0 ? undefined : billingProvider?.id,
        renderingProviderUserId: renderingProvider?.id,
        insuranceDetails: {
          assignment: isEmptyString(assignment?.code) ? undefined : assignment?.code,
          insuranceId: insurance?.insuranceId === 0 ? null : insurance?.insuranceId,
          insuranceType: isEmptyString(insuranceType?.code) ? undefined : insuranceType?.code,
          insurancePolicy: isEmptyString(insurancePolicy?.code) ? undefined : insurancePolicy?.code,
        },
        serviceFacilityAddressId: serviceFacility?.addressId === 0
          ? undefined : serviceFacility?.addressId,
      },
      details: {
        employment: {
          isRelatedToEmployment: isRelatedToEmployment ?? false,
        },
        autoAccident: {
          isAutoAccident: autoAccident?.isAutoAccident ?? false,
          autoAccidentState: autoAccident?.autoAccidentState,
        },
        otherAccident: {
          isOtherAccident: otherAccident?.isOtherAccident ?? false,
        },
        additionalInformation,
        dates: dates?.map(({ item, date }) => ({
          qualifier: item.qualifier,
          date: formatDateOnly(date),
        })),
        priorAuthCode,
        resubmissionCode: isEmptyString(resubmissionCode?.code)
          ? undefined : resubmissionCode?.code,
        originalReferenceNumber,
      },
      notes: {
        flag,
        notesText,
      },
    },
  };
}

export function normalizeClaimSplits(claim: SplitClaimsDTO): ClaimSplit[] {
  return (claim?.splitClaims ?? []).map((claimSplit) => ({
    ...claimSplit,
    claimId: (claimSplit?.claimId ?? 0).toString(),
    serviceCodes: (claimSplit.serviceCodes ?? []).map((serviceCode) => ({
      ...serviceCode,
      serviceCodeDetails: {
        ...(serviceCode?.serviceCodeDetails ?? {}),
        primaryClaimId: (claimSplit?.claimId ?? 0).toString(),
      },
    })),
  }));
}

function serializeServiceCodesForSplit(
  serviceCodes: VisitNoteBillingCodeDTO[],
  claimId: number,
): VisitNoteBillingCodeDTO[] {
  return (serviceCodes ?? []).map((service) => ({
    ...service,
    serviceCodeDetails: {
      ...service?.serviceCodeDetails,
      primaryClaimId: claimId,
    },
  }));
}

export function serializeClaimSplits(splits: ClaimSplit[]): SplitClaimsDTO {
  return {
    splitClaims: splits.map((claimSplit) => {
      const claimId = /^new-/ig.test(claimSplit.claimId) ? 0 : Number(claimSplit.claimId);
      return {
        ...claimSplit,
        insuranceId: claimSplit?.insuranceId === 0 ? null : claimSplit?.insuranceId,
        externalId: claimSplit?.externalId ?? '',
        status: claimSplit?.status ?? 'Draft',
        claimId,
        serviceCodes: serializeServiceCodesForSplit(claimSplit?.serviceCodes ?? [], claimId),
      };
    }),
  };
}

export function serializeEOBWithInsurancePaymentRequest(
  data: EOBWithInsurancePaymentRequestDTO,
): EOBWithInsurancePaymentRequestDTO {
  const { discriminator } = data?.insurancePayment?.paymentMethod ?? {};

  const isACHPayment = /^ach/i.test(discriminator ?? '');
  const achFieldValues = pick(data?.insurancePayment?.paymentMethod, achFields);

  const achInfo = isACHPayment ? {
    ...achFieldValues,
    ...getDefaultIf(achFieldValues, null, isEmptyString),
  } : {};
  return {
    ...data,
    ...(!isNil(data?.insurancePayment) && {
      insurancePayment: {
        ...data.insurancePayment,
        paymentMethod: {
          ...data.insurancePayment?.paymentMethod,
          ...setDefaultValueIf(achInfo, null, isEmptyString),
        },
      },
    }),
  };
}

export const normalizePatientInvoice = (data: PatientStatementDTO): PatientInvoice => ({
  ...data,
  visitNotesForStatement: toPairs(data.billingData ?? {}).reduce((acc, [key, value]) => {
    const { billingCodes, customServiceCodes, encounterId, isOutOfPocket } = value;
    const allocation = withServiceCodesAllocation(
      [...(billingCodes ?? []), ...(customServiceCodes ?? [])],
      { encounterId: encounterId ?? 0 },
      isOutOfPocket,
      true,
    );

    return {
      ...acc,
      [key]: {
        ...value,
        patientBalance: data?.patientEncounterFinancial?.[key] ?? {},
        allocation,
      },
    };
  }, {}),
});

export function normalizeCPTPrice({
  chargeMasterId: id,
  billingCodeId: codeId,
  ...data
}: ChargeMasterDTO) {
  return {
    id,
    codeId,
    isCustom: false,
    ...data,
  };
}

export function normalizeCustomServicePrice({
  customServiceCodeId: codeId,
  customChargeMasterId: id,
  ...data
}: CustomChargeMasterDTO) {
  return {
    id,
    codeId,
    isCustom: true,
    ...data,
  };
}

export function serializePrice({
  id,
  codeId,
  isCustom,
  chargeAmount,
  billingCode,
  customServiceCode,
}: Price) {
  return {
    ...(isCustom
      ? {
        customChargeMasterId: id,
        customServiceCodeId: codeId ?? customServiceCode?.customServiceCodeId,
        customServiceCode,
      }
      : {
        chargeMasterId: id,
        billingCodeId: codeId ?? billingCode?.billingCodeId,
        billingCode,
      }
    ),
    chargeAmount,
  };
}

export function serializePatientStatementPayload(
  data: PatientStatementRequest,
): PatientStatementRequestDTO {
  const { phone, billInfo = [], ...payload } = data;

  return {
    ...pickBy(payload, (value) => !isEmptyString(value)),
    ...(isEmptyString(phone)) ? {} : { phone: normalizePhoneNumber(phone ?? '') },
    ...(isNil(billInfo) ? {} : {
      paymentByEncounters: billInfo.map(
        ({ encounterId, balance }) => ({ encounterId, paymentAmount: balance }),
      ),
    }),

  };
}

export function serializeWriteOffs(
  data: WriteOffByEncounterDTO[],
  encounterId: number | string,
): WriteOffRequestDTO {
  const writeOffs = data?.map(({ encounterId: _encounterId, ...writeOff }) => ({
    ...writeOff,
    writeOffByEncounterId: encounterId,
  }));

  return {
    writeOffs: {
      [encounterId]: writeOffs as WriteOffDTO[],
    },
  };
}
