import React, {
  useState,
  useEffect,
  FocusEvent,
} from 'react';
import clsx from 'clsx';
import defer from 'lodash/defer';
import {
  IMaskMixin,
  IMaskMixinProps,
} from 'react-imask';
import {
  InputMask,
} from 'imask';
import TextField, {
  TextFieldProps,
} from '@mui/material/TextField';
import {
  isEmptyString,
} from 'utils/misc';

type Props = IMaskMixinProps<any, any> & TextFieldProps;

const MaskedInput = IMaskMixin(({
  inputRef,
  className,
  onChange,
  ...props
}: Props) => (
  <TextField
    {...props}
    inputRef={inputRef}
    className={className}
  />
));

export default function MaskedTextField({
  onAccept,
  className,
  value,
  onFocus,
  ...props
}: Props) {
  const [isFilled, setIsFilled] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const handleAccept = (value: string, mask: InputMask<any>) => {
    setIsFilled(mask.masked.isFilled);
    setIsCompleted(mask.masked.isComplete);
    onAccept?.(value, mask);
  };

  useEffect(() => {
    if (!isEmptyString(value)) {
      setIsCompleted(true);
      setIsFilled(true);
    }
  }, []);

  return (
    <MaskedInput
      {...props}
      onFocus={(event: FocusEvent<HTMLInputElement>) => {
        const input = event.target;

        defer(() => {
          if (typeof input.setSelectionRange === 'function') {
            input.setSelectionRange(0, 0);
          }
        });
        onFocus?.(event);
      }}
      value={value}
      className={clsx('populate-input', className)}
      inputProps={{
        ...(props.inputProps ?? {}),
        spellCheck: false,
      }}
      InputProps={{
        ...(props.InputProps ?? {}),
        classes: {
          input: clsx({
            '!text-gray-500': !isCompleted || !isFilled,
          }),
        },
      }}
      onAccept={handleAccept}
    />
  );
}
