import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from 'react-query';
import isNil from 'lodash/isNil';
import {
  postApiSettingsAddAppointmentTypeSetting,
  postApiSettingsAddElectronicPayerEntryConfig,
  putApiSettingsUpdateElectronicPayerEntryConfig,
  postApiSettingsUpdateAppointmentTypeSetting,
  getApiVisitNoteGetUserFavoriteMedicalCodes,
  postApiOrganizationGetConsentFormEditor,
  deleteApiOrganizationDeleteConsentForm,
  postApiOrganizationAddConsentForm,
  postApiOrganizationUpdateConsentForm,
  postApiTemplateAddField,
  deleteApiTemplateDeleteField,
  putApiTemplateEditField,
  getApiTemplateGetFieldFieldId,
  getApiTemplateGetFieldList,
  putApiSettingsUpdatePaperPayerEntry,
  postApiSettingsAddPaperPayerEntry,
  deleteApiSettingsDeletePaperPayerEntry,
  deleteApiSettingsDeleteElectronicPayerEntryConfig,
  postApiUserCreateUser,
  postApiUserUpdateUser,
  getApiSettingsGetUserManagementSettings,
} from 'endpoints';
import {
  AppointmentTypeSettingDTO,
  ConsentFormEditorDTO,
  DeleteRequestDTO,
  OrganizationalConsentFormsUploadDTO,
  OrganizationalConsentFormDTO,
  PayerEntryConfigDTO,
  UserFavoritedMedicalCodesResponseDTO,
  TemplateFieldDTO,
  None,
  GetApiTemplateGetFieldListParams,
  TemplateFieldDTOPaginatedResponseDTO,
  PaperPayerEntryDTO,
  UpdatePaperPayerRequestDTO,
  UserDTO,
  UserManagementSettingsDTO,
} from 'dtos';
import {
  isEmptyNumber,
} from 'utils/misc';
import usePrefetchedData from 'pages/Dashboard/hooks/usePrefetchedData';
import {
  useEffect,
} from 'react';
import {
  settingsEventChannel,
} from 'pages/Dashboard/services/eventChannels';
import {
  getAppointmentTypes,
} from 'views/EMR/Settings/transport';
import {
  AppointmentTypes,
} from 'views/EMR/Settings/Appointments/types';
import {
  UserRequest,
} from 'views/EMR/Settings/Users/Dialog';

export function useUpdateConsentForm():
  UseMutationResult<OrganizationalConsentFormDTO, Error, OrganizationalConsentFormDTO> {
  return useMutation<OrganizationalConsentFormDTO, Error, OrganizationalConsentFormDTO>(
    postApiOrganizationUpdateConsentForm,
  );
}

export function useAddConsentForm():
  UseMutationResult<OrganizationalConsentFormDTO, Error, OrganizationalConsentFormsUploadDTO> {
  return useMutation<OrganizationalConsentFormDTO, Error, OrganizationalConsentFormsUploadDTO>(
    postApiOrganizationAddConsentForm,
  );
}

export function useDeleteTemplate():
  UseMutationResult<DeleteRequestDTO, Error, DeleteRequestDTO> {
  return useMutation<DeleteRequestDTO, Error, DeleteRequestDTO>(
    deleteApiOrganizationDeleteConsentForm,
  );
}

export function useGetFormEditor(templateId: string): UseQueryResult<
  ConsentFormEditorDTO, Error> {
  return useQuery<ConsentFormEditorDTO, Error>(
    'consent-form-editor',
    () => postApiOrganizationGetConsentFormEditor({ templateId }),
    { enabled: !isNil(templateId) },
  );
}

export function useUpsertAppointmentType(isNew?: boolean):
  UseMutationResult<AppointmentTypeSettingDTO, Error, AppointmentTypeSettingDTO> {
  return useMutation<AppointmentTypeSettingDTO, Error, AppointmentTypeSettingDTO>(
    (data: AppointmentTypeSettingDTO) => (
      isNew
        ? postApiSettingsAddAppointmentTypeSetting(data)
        : postApiSettingsUpdateAppointmentTypeSetting(data)),
  );
}

export function useGetAppointmentTypes(): AppointmentTypes {
  const {
    data: appointmentSettings,
    refetch: refetchAppointmentTypeSettings,
    isLoading,
  } = usePrefetchedData<AppointmentTypes>({
    key: 'appointment-type-settings',
    fetchFn: getAppointmentTypes,
  });

  useEffect(() => {
    const unsubscribe = settingsEventChannel.on('onAppointmentTypesChange', () => {
      refetchAppointmentTypeSettings?.();
    });
    return () => { unsubscribe(); };
  }, []);

  const result = appointmentSettings ?? {
    appointmentTypes: [],
    durationByType: {},
  };

  return {
    ...result,
    isLoading,
  };
}

export function useUpsertElectronicPayerConfig():
  UseMutationResult<PayerEntryConfigDTO, Error, PayerEntryConfigDTO> {
  return useMutation<PayerEntryConfigDTO, Error, PayerEntryConfigDTO>(
    (payload) => (
      isNil(payload?.payerEntryConfigId)
        ? postApiSettingsAddElectronicPayerEntryConfig(payload)
        : putApiSettingsUpdateElectronicPayerEntryConfig(payload)
    ),
  );
}

export function useUpsertPaperPayerConfig():
  UseMutationResult<PaperPayerEntryDTO, Error, UpdatePaperPayerRequestDTO> {
  return useMutation<PaperPayerEntryDTO, Error, UpdatePaperPayerRequestDTO>(
    (payload) => (
      isNil(payload?.payerEntryId)
        ? postApiSettingsAddPaperPayerEntry(payload)
        : putApiSettingsUpdatePaperPayerEntry(payload)
    ),
  );
}

export function useDeletePayerConfig():
  UseMutationResult< void, Error, PayerEntryConfigDTO > {
  return useMutation< void, Error, PayerEntryConfigDTO >(
    async (data) => {
      if (data?.payerEntry?.isPaper) {
        await deleteApiSettingsDeletePaperPayerEntry({
          payerEntryId: data?.payerEntryId ?? 0,
        });
      } else {
        await deleteApiSettingsDeleteElectronicPayerEntryConfig({
          payerEntryConfigId: data?.payerEntryConfigId ?? 0,
        });
      }
    },
  );
}

export function useGetFavoriteCodes(): UseQueryResult<UserFavoritedMedicalCodesResponseDTO, Error> {
  return useQuery<UserFavoritedMedicalCodesResponseDTO, Error>(
    ['template-favorite'],
    getApiVisitNoteGetUserFavoriteMedicalCodes,
  );
}

export function useGetFields(
  params: GetApiTemplateGetFieldListParams,
): UseQueryResult<TemplateFieldDTOPaginatedResponseDTO, Error> {
  return useQuery<TemplateFieldDTOPaginatedResponseDTO, Error>(
    ['fields', JSON.stringify(params)],
    () => getApiTemplateGetFieldList(params),
  );
}

export function useGetFieldById(id: number = 0): UseQueryResult<TemplateFieldDTO, Error> {
  return useQuery<TemplateFieldDTO, Error>(
    ['field', id],
    () => getApiTemplateGetFieldFieldId(id),
    { enabled: !isEmptyNumber(id) },
  );
}

export function useUpsertField(): UseMutationResult<TemplateFieldDTO, Error, TemplateFieldDTO> {
  return useMutation<TemplateFieldDTO, Error, TemplateFieldDTO>(
    async (data) => {
      const hasId = !isEmptyNumber(data?.templateFieldId);
      const requestFn = hasId ? putApiTemplateEditField : postApiTemplateAddField;
      const response = await requestFn(data);
      return response as TemplateFieldDTO;
    },
  );
}

export function useDeleteField(): UseMutationResult<None, Error, TemplateFieldDTO> {
  return useMutation<None, Error, TemplateFieldDTO>(deleteApiTemplateDeleteField);
}

export function useGetUserManagementSettings(): UseQueryResult<UserManagementSettingsDTO, Error> {
  return usePrefetchedData<UserManagementSettingsDTO>({
    key: 'user-settings',
    fetchFn: getApiSettingsGetUserManagementSettings,
  });
}

export function useUpsertUser(isNew?: boolean):
  UseMutationResult<UserDTO, Error, UserRequest> {
  return useMutation<UserDTO, Error, UserRequest>(
    (data) => (isNew
      ? postApiUserCreateUser(data)
      : postApiUserUpdateUser(data)
    ),
  );
}
