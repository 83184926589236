/**
 * Generated by orval v6.14.3 🍺
 * Do not edit manually.
 * PopulateAPI
 * OpenAPI spec version: v1
 */
import type {
  PatientPaymentResponseDTO,
  PatientPaymentQueryDTO,
  AllocationResponseBaseDTO,
  PatientPaymentRequestDTO,
  CommunicationResult,
  OnlinePaymentRequestDTO,
  None,
  WriteOffRequestDTO,
  InsurancePaymentResponseDTO,
  GetApiBillingGetInsurancePaymentsParams,
  InsurancePaymentDTO,
  InsurancePaymentRequestDTO,
  DeleteRequestDTO,
  EOBWithInsurancePaymentResponseDTOPaginatedResponseDTO,
  GetApiBillingGetEOBsParams,
  EOBWithInsurancePaymentResponseDTO,
  GetApiBillingGetEOBParams,
  EOBWithInsurancePaymentRequestDTO,
  EncounterBillingSummaryResponseDTO,
  PatientBillingSummaryResponseDTO,
  BatchAllocationResponseDTO,
  AllocationRequestDTO,
  EncounterBillingDataDTO,
  GetApiBillingGetEncounterBillingDataParams,
  GetApiBillingGetUnpaidEncounterBillingDataParams,
  EOBDataForClaimViewDTO,
  GetApiBillingGetEOBByPatientPatientIdParams,
  PatientStatementDTO,
  PatientStatementRequestDTO,
  MultiMediaResponseDTO,
  BulkPatientStatementTriggerResponseDTO,
  BulkPatientStatementGenerationDTO,
  BulkPatientStatementResponseDTO,
  BulkPatientStatementRequestDTO,
  FileResultDTO,
  GetApiBillingDownloadBulkPatientStatementParams,
  ReportResponseDTO,
  BulkPatientStatementCompletedDTO,
  MultiMediaResponse,
  PatientInvoiceRequestDTO,
  PatientStatementReminderResponseDTO,
  GetApiBillingGetStatementLastSentInfoParams,
  SquareInvoiceSummaryResponseDTO,
  GetApiBillingGetInvoiceSummaryParams,
  BillingCodeDTOPaginatedResponseDTO,
  GetApiBillingCodeSearchParams,
  GetApiBillingCodeGetFavoritesForUserParams,
  GetApiBillingCodeGetAliasesForUserParams,
  BillingCodeDTO,
  BillingCodeFavoriteRequestDTO,
  BillingCodeAliasRequestDTO,
  BlockedTimeSlotDTO,
  GetApiBlockedTimeSlotGetBlockedTimeSlotParams,
  SaveBlockedTimeSlotResponse,
  DeleteCalendarSlotResponseDTO,
  DeleteApiBlockedTimeSlotDeleteParams,
  DeletePartialBlockedTimeSlotRequest,
  CheckEligibilitySuccessResponse,
  EligibilityRequestDTO,
  PayerEntryDTO,
  GetApiCHSearchInsuranceParams,
  GetApiCHSearchInsuranceByOrgParams,
  GetApiCHGetFavoriteInsurancesByOrgParams,
  StediReportWebHookTransactionDTO,
  StediEraPDFWebHookFileProcessedDTO,
  GetApiCHTestProcessStediERAParams,
  ChargeMasterDTOPaginatedResponseDTO,
  GetApiChargeSearchChargeMasterParams,
  CustomChargeMasterDTOPaginatedResponseDTO,
  GetApiChargeSearchCustomChargeMasterParams,
  ChargeMasterDTO,
  CustomChargeMasterDTO,
  ChargesResponseDTO,
  ChargesRequestDTO,
  NPISearchResponseDTO,
  GetApiClaimSearchExternalProviderParams,
  ClaimAdjustmentReasonCodeDTO,
  GetApiClaimSearchAdjustmentReasonCodeParams,
  NdcCodeDTOPaginatedResponseDTO,
  GetApiClaimSearchNdcCodesParams,
  ClaimBundleDTO,
  PostApiClaimTransmitClaimsToClearinghouseByTagParams,
  MarkClaimStatusDTO,
  PrintClaimDTO,
  SplitClaimsDTO,
  CreateSecondaryClaimDTO,
  PatientInfoCommuniqueDTO,
  ReminderCommuniqueRequestDTO,
  ConfirmAppointmentCommuniqueResponseDTO,
  GetApiCommunicationGetAppointmentCommuniqueByTokenParams,
  CommuniqueByPatientInfoRequestDTO,
  ConsentFormInfoDTO,
  OrganizationalConsentFormDTO,
  ConsentFormsCommuniqueDTO,
  CustomServiceCodeDTOPaginatedResponseDTO,
  GetApiCustomServiceCodeSearchParams,
  CustomServiceCodeDTO,
  CustomServiceCodeFavoriteRequestDTO,
  SendFaxDTO,
  PostApiEFaxResendFaxParams,
  FaxViewDTOPaginatedResponseDTO,
  GetFaxesRequestDTO,
  InboundFaxDTO,
  PostApiEFaxSetInboundFaxInternalStatusParams,
  PatientMultiMediaResponseDTO,
  UploadToPatientChartDTO,
  FaxContactDTO,
  AddFaxContactDTO,
  FaxContactDTOPaginatedResponseDTO,
  GetApiEFaxGetFaxContactsParams,
  DeleteApiEFaxDeleteFaxContactParams,
  GetApiEFaxDownloadFaxParams,
  OutboundWebhookData,
  InboundWebhookData,
  EncounterViewModel,
  GetApiEncounterGetEncounterSummariesByDateParams,
  EncounterDetailsViewModel,
  GetApiEncounterGetEncounterDetailsByIdParams,
  AppointmentDTO,
  GetApiEncounterGetAppointmentsByDateParams,
  CalendarEventsResponseDTO,
  GetApiEncounterGetCalendarEventsByDateParams,
  GetApiEncounterGetAppointmentsByDateRangeParams,
  GetApiEncounterGetCalendarEventsByDateRangeParams,
  AppointmentDetailsDTO,
  CreateAppointmentResponseDTO,
  CreateAppointmentRequestDTO,
  DeleteAppointmentRequestDTO,
  DoctorsAppointmentSummaryDTO,
  GetApiEncounterGetDailyAppointmentBreakdownByDateRangeParams,
  ProviderDTO,
  GetApiEncounterGetReferringProviderFromLastAppointmentParams,
  AppointmentConfirmationRequestDTO,
  AppointmentConfirmationViaVoiceAgentRequestDTO,
  ModifyAppointmentDetailsRequestDTO,
  ModifyAppointmentStatusRequestDTO,
  ModifyAppointmentPaymentTypeRequestDTO,
  PrintEncounterScheduleRequestDTO,
  WriteOffByEncounterDTO,
  PostApiEncounterGetWriteoffsByEncounterIdParams,
  ERxNotificationsCountResponseDTO,
  ERxWebpageURLResponseDTO,
  GetApiERxPatientWebpageURLParams,
  ERxMedicationSearchResponseDTO,
  GetApiERxMedicationSearchParams,
  ERxDrugAllergySearchResponseDTO,
  GetApiERxDrugAllergySearchParams,
  ERxPharmacySearchResponseDTO,
  GetApiERxPharmacySearchParams,
  MultiMediaDeleteDTO,
  MultiMediaRequestDTO,
  PatientMultiMediaUploadDTO,
  PatientMultiMediaUpdateDTO,
  BillingMultiMediaResponseDTO,
  BillingMultiMediaUploadDTO,
  OrgMultiMediaUploadDTO,
  PatientMultimediaRequestDTO,
  GetApiMultimediaGetFaxMultimediaByFaxIdParams,
  OrganizationDTO,
  GetApiOrganizationGetOrgByPatientIdParams,
  OrganizationByIntakeRequestDTO,
  OrganizationalConsentFormsUploadDTO,
  ConsentFormEditorDTO,
  ConsentFormEditorRequestDTO,
  ConsentFormResponseListDTO,
  ConsentFormQueryDTO,
  PatientDTO,
  CreatePatientRequestDTO,
  SearchPatientResultDTOPaginatedResponseDTO,
  GetApiPatientSearchPatientsParams,
  PatientCountSummaryDTO,
  PatientMedicalBackgroundResponseDTO,
  GetApiPatientGetPatientInsurancesByIdIdParams,
  PatientMedicalHistoryRequestDTO,
  ISyncPatientResult,
  PatientPharmacyAllergyMedicationRequestDTO,
  PatientInsurancesRequestDTO,
  ReasonForVisitDTO,
  PatientWithHPIAttributesDTO,
  OrganizationalConsentFormsResponseDTO,
  GeneralConsentByPatientDTO,
  DosespotConsentByPatientDTO,
  ProceduralConsentByPatientDTO,
  PatientDOBDTO,
  EncounterLedger,
  GetApiPatientPrintPatientFaceSheetParams,
  ProviderAvailabilityDTO,
  GetApiProviderAvailabilityGetProviderAvailabilityParams,
  SaveAvailabilityResponse,
  DeleteApiProviderAvailabilityDeleteParams,
  DeletePartialRequest,
  ReportSettingsResponseDTO,
  TriggerReportResponseDTO,
  ReportRequestDTOBase,
  ReportRequestDTO,
  GetApiReportDownloadReportParams,
  PostApiReportDeleteReportParams,
  ReportCompleteDTO,
  SettingsDTO,
  AppointmentTypeSettingDTO,
  UserManagementSettingsDTO,
  UserSettingDTO,
  PayerEntryConfigDTOPaginatedResponseDTO,
  GetApiSettingsGetPayerEntryConfigsByTypeParams,
  PaperPayerEntryDTO,
  CreatePaperPayerRequestDTO,
  UpdatePaperPayerRequestDTO,
  DeleteApiSettingsDeletePaperPayerEntryParams,
  PayerEntryConfigDTO,
  DeleteApiSettingsDeleteElectronicPayerEntryConfigParams,
  GetApiSquareAuthorizationAuthorizationRedirectParams,
  GetApiSquarePaymentsGetDeviceByDeviceIdParams,
  PostApiSquarePaymentsCreateDeviceCodeForLocationParams,
  PostApiSquarePaymentsCreateTerminalCheckoutParams,
  PaymentDTO,
  GetPaymentRequest,
  CancelledTerminalCheckoutDTO,
  CancelTerminalCheckoutRequest,
  PostApiSquarePaymentsCreateQuickPayCheckoutParams,
  StickyDTO,
  GetApiStickyGetAllStickiesByPatientIdParams,
  GetApiStickyGetStickyByIdParams,
  CreateStickyRequestDTO,
  UpdateStickyRequestDTO,
  DeleteStickyRequestDTO,
  SectionTemplateDTO,
  SaveSectionTemplateRequestDTO,
  GetApiTemplateRetrieveSectionTemplateParams,
  DeleteSectionTemplateRequestDTO,
  TemplateListItemDTOPaginatedResponseDTO,
  GetApiTemplateGetSectionTemplateListParams,
  MasterTemplateDTO,
  SaveMasterTemplateRequestDTO,
  GetApiTemplateRetrieveMasterTemplateParams,
  DeleteMasterTemplateRequestDTO,
  GetApiTemplateGetMasterTemplateListParams,
  TemplateFieldDTOPaginatedResponseDTO,
  GetApiTemplateGetFieldListParams,
  TemplateFieldDTO,
  TemplateFieldRequestDTO,
  ToDoPaginatedCollectionDTO,
  ToDoRequestDTO,
  ToDoResponseWithReferencedObjectsDTO,
  GetApiToDoGetToDoByIdParams,
  ToDoCommentsCollectionDTO,
  GetApiToDoGetCommentsByToDoIdParams,
  ToDoResponseDTO,
  CreateToDoRequestDTO,
  ToDoCommentDTO,
  ToDoCommentRequestDTO,
  ToDoUpdateRequestDTO,
  ToDoCountResponseDTO,
  GetApiToDoGetFaxToDosParams,
  UserDTO,
  LoggedInUserInfoDTO,
  CreateUserRequestDTO,
  UpdateUserRequestDTO,
  NablaAuthenticationResponseDTO,
  VisitNoteDTO,
  PatientVisitDTO,
  VisitNotePageResponseDTO,
  AppointmentInfoForVisitNoteDTO,
  UserFavoritedMedicalCodesResponseDTO,
  VisitNoteBillingAssessmentCodeDTO,
  VisitNoteBillingCodeDTO,
  VisitNoteBillingCustomServiceCodeDTO,
  GetApiVisitNoteGetHPIInParagraphFormParams,
  CPTCodeSuggestionsDTO,
  AddendumDTO,
  GetApiVisitNoteRetrieveLastVisitNoteParams,
  VisitNoteSummaryDTO,
  GetApiVisitNoteGetPastVisitNotesForPatientParams,
  GetApiVisitNoteGetSpecificVisitNoteForPatientParams,
  PostApiVisitNoteUnFinalizeParams,
  MipsResponseDTO,
  GetApiVisitNoteGetMipsSubmissionDataParams,
  PostCallPatientForAppointmentConfirmationParams,
  PostCallForAppointmentConfirmationParams,
  ChatMessageContent,
  GetApiVoiceAssistantGetQueryAnswerParams,
  GetApiVoiceAssistantGetContextFromPopulateParams
} from './dtos'
import { sendRequest } from './utils/httpClient';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;




// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


  export const postApiAnvilHandleAction = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/Anvil/HandleAction`, method: 'post'
    },
      options);
    }
  
export const postApiBillingGetPatientPayments = (
    patientPaymentQueryDTO: PatientPaymentQueryDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PatientPaymentResponseDTO>(
      {url: `/api/Billing/GetPatientPayments`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: patientPaymentQueryDTO
    },
      options);
    }
  
export const postApiBillingCreatePatientPayment = (
    patientPaymentRequestDTO: PatientPaymentRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<AllocationResponseBaseDTO>(
      {url: `/api/Billing/CreatePatientPayment`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: patientPaymentRequestDTO
    },
      options);
    }
  
export const postApiBillingSendBillForOnlinePayment = (
    onlinePaymentRequestDTO: OnlinePaymentRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<CommunicationResult>(
      {url: `/api/Billing/SendBillForOnlinePayment`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: onlinePaymentRequestDTO
    },
      options);
    }
  
export const postApiBillingAddOrUpdateWriteOffs = (
    writeOffRequestDTO: WriteOffRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/Billing/AddOrUpdateWriteOffs`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: writeOffRequestDTO
    },
      options);
    }
  
export const getApiBillingGetInsurancePayments = (
    params?: GetApiBillingGetInsurancePaymentsParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<InsurancePaymentResponseDTO>(
      {url: `/api/Billing/GetInsurancePayments`, method: 'get',
        params
    },
      options);
    }
  
export const postApiBillingCreateInsurancePayment = (
    insurancePaymentRequestDTO: InsurancePaymentRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<InsurancePaymentDTO>(
      {url: `/api/Billing/CreateInsurancePayment`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: insurancePaymentRequestDTO
    },
      options);
    }
  
export const postApiBillingModifyInsurancePayment = (
    insurancePaymentRequestDTO: InsurancePaymentRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<InsurancePaymentDTO>(
      {url: `/api/Billing/ModifyInsurancePayment`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: insurancePaymentRequestDTO
    },
      options);
    }
  
export const deleteApiBillingDeleteInsurancePayment = (
    deleteRequestDTO: DeleteRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/Billing/DeleteInsurancePayment`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteRequestDTO
    },
      options);
    }
  
export const getApiBillingGetEOBs = (
    params?: GetApiBillingGetEOBsParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<EOBWithInsurancePaymentResponseDTOPaginatedResponseDTO>(
      {url: `/api/Billing/GetEOBs`, method: 'get',
        params
    },
      options);
    }
  
export const getApiBillingGetEOB = (
    params?: GetApiBillingGetEOBParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<EOBWithInsurancePaymentResponseDTO | void>(
      {url: `/api/Billing/GetEOB`, method: 'get',
        params
    },
      options);
    }
  
export const postApiBillingCreateEOBWithInsurancePayment = (
    eOBWithInsurancePaymentRequestDTO: EOBWithInsurancePaymentRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<EOBWithInsurancePaymentResponseDTO>(
      {url: `/api/Billing/CreateEOBWithInsurancePayment`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: eOBWithInsurancePaymentRequestDTO
    },
      options);
    }
  
export const postApiBillingModifyEOB = (
    eOBWithInsurancePaymentRequestDTO: EOBWithInsurancePaymentRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<EOBWithInsurancePaymentResponseDTO>(
      {url: `/api/Billing/ModifyEOB`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: eOBWithInsurancePaymentRequestDTO
    },
      options);
    }
  
export const deleteApiBillingDeleteEOB = (
    deleteRequestDTO: DeleteRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/Billing/DeleteEOB`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteRequestDTO
    },
      options);
    }
  
export const getApiBillingGetBillingSummaryDataByEncounterIdEncounterId = (
    encounterId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<EncounterBillingSummaryResponseDTO>(
      {url: `/api/Billing/GetBillingSummaryDataByEncounterId/${encounterId}`, method: 'get'
    },
      options);
    }
  
export const getApiBillingGetBillingSummaryDataByPatientIdPatientId = (
    patientId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PatientBillingSummaryResponseDTO>(
      {url: `/api/Billing/GetBillingSummaryDataByPatientId/${patientId}`, method: 'get'
    },
      options);
    }
  
export const getApiBillingGetAutoAllocationPreviewPatientId = (
    patientId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<BatchAllocationResponseDTO>(
      {url: `/api/Billing/GetAutoAllocationPreview/${patientId}`, method: 'get'
    },
      options);
    }
  
export const postApiBillingCreateAutoAllocationPatientId = (
    patientId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<BatchAllocationResponseDTO>(
      {url: `/api/Billing/CreateAutoAllocation/${patientId}`, method: 'post'
    },
      options);
    }
  
export const postApiBillingCreateAllocation = (
    allocationRequestDTO: AllocationRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<BatchAllocationResponseDTO>(
      {url: `/api/Billing/CreateAllocation`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: allocationRequestDTO
    },
      options);
    }
  
export const postApiBillingCreateDeposit = (
    patientPaymentRequestDTO: PatientPaymentRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<AllocationResponseBaseDTO>(
      {url: `/api/Billing/CreateDeposit`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: patientPaymentRequestDTO
    },
      options);
    }
  
export const getApiBillingGetEncounterBillingData = (
    params?: GetApiBillingGetEncounterBillingDataParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<EncounterBillingDataDTO>(
      {url: `/api/Billing/GetEncounterBillingData`, method: 'get',
        params
    },
      options);
    }
  
export const getApiBillingGetUnpaidEncounterBillingData = (
    params?: GetApiBillingGetUnpaidEncounterBillingDataParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<EncounterBillingDataDTO>(
      {url: `/api/Billing/GetUnpaidEncounterBillingData`, method: 'get',
        params
    },
      options);
    }
  
export const getApiBillingGetEOBByPatientPatientId = (
    patientId: number,
    params?: GetApiBillingGetEOBByPatientPatientIdParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<EOBDataForClaimViewDTO[]>(
      {url: `/api/Billing/GetEOBByPatient/${patientId}`, method: 'get',
        params
    },
      options);
    }
  
export const postApiBillingGetPatientStatement = (
    patientStatementRequestDTO: PatientStatementRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PatientStatementDTO>(
      {url: `/api/Billing/GetPatientStatement`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: patientStatementRequestDTO
    },
      options);
    }
  
export const postApiBillingPrintStatementPdf = (
    patientStatementRequestDTO: PatientStatementRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<MultiMediaResponseDTO>(
      {url: `/api/Billing/PrintStatementPdf`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: patientStatementRequestDTO
    },
      options);
    }
  
export const postApiBillingSendStatementPdf = (
    patientStatementRequestDTO: PatientStatementRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/Billing/SendStatementPdf`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: patientStatementRequestDTO
    },
      options);
    }
  
export const postApiBillingGenerateBulkPatientStatement = (
    bulkPatientStatementGenerationDTO: BulkPatientStatementGenerationDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<BulkPatientStatementTriggerResponseDTO>(
      {url: `/api/Billing/GenerateBulkPatientStatement`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: bulkPatientStatementGenerationDTO
    },
      options);
    }
  
export const postApiBillingGetBulkPatientStatements = (
    bulkPatientStatementRequestDTO: BulkPatientStatementRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<BulkPatientStatementResponseDTO>(
      {url: `/api/Billing/GetBulkPatientStatements`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: bulkPatientStatementRequestDTO
    },
      options);
    }
  
export const getApiBillingDownloadBulkPatientStatement = (
    params?: GetApiBillingDownloadBulkPatientStatementParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<FileResultDTO>(
      {url: `/api/Billing/DownloadBulkPatientStatement`, method: 'get',
        params
    },
      options);
    }
  
export const postApiBillingHandleBulkPatientStatementComplete = (
    bulkPatientStatementCompletedDTO: BulkPatientStatementCompletedDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ReportResponseDTO>(
      {url: `/api/Billing/HandleBulkPatientStatementComplete`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: bulkPatientStatementCompletedDTO
    },
      options);
    }
  
export const postApiBillingPrintInvoicePdf = (
    patientInvoiceRequestDTO: PatientInvoiceRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<MultiMediaResponse>(
      {url: `/api/Billing/PrintInvoicePdf`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: patientInvoiceRequestDTO
    },
      options);
    }
  
export const getApiBillingGetStatementLastSentInfo = (
    params?: GetApiBillingGetStatementLastSentInfoParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PatientStatementReminderResponseDTO | void>(
      {url: `/api/Billing/GetStatementLastSentInfo`, method: 'get',
        params
    },
      options);
    }
  
export const getApiBillingGetInvoiceSummary = (
    params?: GetApiBillingGetInvoiceSummaryParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<SquareInvoiceSummaryResponseDTO>(
      {url: `/api/Billing/GetInvoiceSummary`, method: 'get',
        params
    },
      options);
    }
  
export const getApiBillingSyncFinancials = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<SquareInvoiceSummaryResponseDTO>(
      {url: `/api/Billing/SyncFinancials`, method: 'get'
    },
      options);
    }
  
export const getApiBillingCodeSearch = (
    params?: GetApiBillingCodeSearchParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<BillingCodeDTOPaginatedResponseDTO | void>(
      {url: `/api/BillingCode/Search`, method: 'get',
        params
    },
      options);
    }
  
export const getApiBillingCodeGetFavoritesForUser = (
    params?: GetApiBillingCodeGetFavoritesForUserParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<BillingCodeDTOPaginatedResponseDTO | void>(
      {url: `/api/BillingCode/GetFavoritesForUser`, method: 'get',
        params
    },
      options);
    }
  
export const getApiBillingCodeGetAliasesForUser = (
    params?: GetApiBillingCodeGetAliasesForUserParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<BillingCodeDTOPaginatedResponseDTO | void>(
      {url: `/api/BillingCode/GetAliasesForUser`, method: 'get',
        params
    },
      options);
    }
  
export const putApiBillingCodeToggleFavorite = (
    billingCodeFavoriteRequestDTO: BillingCodeFavoriteRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<BillingCodeDTO | void>(
      {url: `/api/BillingCode/ToggleFavorite`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: billingCodeFavoriteRequestDTO
    },
      options);
    }
  
export const postApiBillingCodeCreateAlias = (
    billingCodeAliasRequestDTO: BillingCodeAliasRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<BillingCodeDTO | void>(
      {url: `/api/BillingCode/CreateAlias`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: billingCodeAliasRequestDTO
    },
      options);
    }
  
export const deleteApiBillingCodeDeleteAlias = (
    billingCodeAliasRequestDTO: BillingCodeAliasRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<BillingCodeDTO | void>(
      {url: `/api/BillingCode/DeleteAlias`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: billingCodeAliasRequestDTO
    },
      options);
    }
  
export const getApiBlockedTimeSlotGetBlockedTimeSlot = (
    params?: GetApiBlockedTimeSlotGetBlockedTimeSlotParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<BlockedTimeSlotDTO>(
      {url: `/api/BlockedTimeSlot/GetBlockedTimeSlot`, method: 'get',
        params
    },
      options);
    }
  
export const postApiBlockedTimeSlotAddOrUpdate = (
    blockedTimeSlotDTO: BlockedTimeSlotDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<SaveBlockedTimeSlotResponse>(
      {url: `/api/BlockedTimeSlot/AddOrUpdate`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: blockedTimeSlotDTO
    },
      options);
    }
  
export const deleteApiBlockedTimeSlotDelete = (
    params?: DeleteApiBlockedTimeSlotDeleteParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<DeleteCalendarSlotResponseDTO>(
      {url: `/api/BlockedTimeSlot/Delete`, method: 'delete',
        params
    },
      options);
    }
  
export const postApiBlockedTimeSlotDeletePartially = (
    deletePartialBlockedTimeSlotRequest: DeletePartialBlockedTimeSlotRequest,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<DeleteCalendarSlotResponseDTO>(
      {url: `/api/BlockedTimeSlot/DeletePartially`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: deletePartialBlockedTimeSlotRequest
    },
      options);
    }
  
export const postApiCHCheckEligibility = (
    eligibilityRequestDTO: EligibilityRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<CheckEligibilitySuccessResponse | void>(
      {url: `/api/CH/CheckEligibility`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: eligibilityRequestDTO
    },
      options);
    }
  
export const getApiCHSearchInsurance = (
    params?: GetApiCHSearchInsuranceParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PayerEntryDTO[]>(
      {url: `/api/CH/SearchInsurance`, method: 'get',
        params
    },
      options);
    }
  
export const getApiCHSearchInsuranceByOrg = (
    params?: GetApiCHSearchInsuranceByOrgParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PayerEntryDTO[]>(
      {url: `/api/CH/SearchInsuranceByOrg`, method: 'get',
        params
    },
      options);
    }
  
export const getApiCHGetFavoriteInsurances = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PayerEntryDTO[]>(
      {url: `/api/CH/GetFavoriteInsurances`, method: 'get'
    },
      options);
    }
  
export const getApiCHGetFavoriteInsurancesByOrg = (
    params?: GetApiCHGetFavoriteInsurancesByOrgParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PayerEntryDTO[]>(
      {url: `/api/CH/GetFavoriteInsurancesByOrg`, method: 'get',
        params
    },
      options);
    }
  
export const postApiCHClaimAcknowledgementHandler = (
    stediReportWebHookTransactionDTO: StediReportWebHookTransactionDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/CH/ClaimAcknowledgementHandler`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: stediReportWebHookTransactionDTO
    },
      options);
    }
  
export const postApiCHElectronicRemittanceAdviceHandler = (
    stediReportWebHookTransactionDTO: StediReportWebHookTransactionDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/CH/ElectronicRemittanceAdviceHandler`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: stediReportWebHookTransactionDTO
    },
      options);
    }
  
export const postApiCHEraPDFHandler = (
    stediEraPDFWebHookFileProcessedDTO: StediEraPDFWebHookFileProcessedDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/CH/EraPDFHandler`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: stediEraPDFWebHookFileProcessedDTO
    },
      options);
    }
  
export const getApiCHTestProcessStediERA = (
    params?: GetApiCHTestProcessStediERAParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/CH/TestProcessStediERA`, method: 'get',
        params
    },
      options);
    }
  
export const getApiChargeSearchChargeMaster = (
    params?: GetApiChargeSearchChargeMasterParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ChargeMasterDTOPaginatedResponseDTO>(
      {url: `/api/Charge/SearchChargeMaster`, method: 'get',
        params
    },
      options);
    }
  
export const getApiChargeSearchCustomChargeMaster = (
    params?: GetApiChargeSearchCustomChargeMasterParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<CustomChargeMasterDTOPaginatedResponseDTO>(
      {url: `/api/Charge/SearchCustomChargeMaster`, method: 'get',
        params
    },
      options);
    }
  
export const postApiChargeCreateChargePolicy = (
    chargeMasterDTO: ChargeMasterDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ChargeMasterDTO>(
      {url: `/api/Charge/CreateChargePolicy`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: chargeMasterDTO
    },
      options);
    }
  
export const postApiChargeCreateCustomCodeChargePolicy = (
    customChargeMasterDTO: CustomChargeMasterDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<CustomChargeMasterDTO>(
      {url: `/api/Charge/CreateCustomCodeChargePolicy`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: customChargeMasterDTO
    },
      options);
    }
  
export const postApiChargeModifyChargePolicy = (
    chargeMasterDTO: ChargeMasterDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ChargeMasterDTO>(
      {url: `/api/Charge/ModifyChargePolicy`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: chargeMasterDTO
    },
      options);
    }
  
export const postApiChargeModifyCustomCodeChargePolicy = (
    customChargeMasterDTO: CustomChargeMasterDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<CustomChargeMasterDTO>(
      {url: `/api/Charge/ModifyCustomCodeChargePolicy`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: customChargeMasterDTO
    },
      options);
    }
  
export const deleteApiChargeDeleteChargePolicy = (
    deleteRequestDTO: DeleteRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ChargeMasterDTO>(
      {url: `/api/Charge/DeleteChargePolicy`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteRequestDTO
    },
      options);
    }
  
export const deleteApiChargeDeleteCustomCodeChargePolicy = (
    deleteRequestDTO: DeleteRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<CustomChargeMasterDTO>(
      {url: `/api/Charge/DeleteCustomCodeChargePolicy`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteRequestDTO
    },
      options);
    }
  
export const postApiChargeGetCharges = (
    chargesRequestDTO: ChargesRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ChargesResponseDTO>(
      {url: `/api/Charge/GetCharges`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: chargesRequestDTO
    },
      options);
    }
  
export const getApiClaimSearchExternalProvider = (
    params?: GetApiClaimSearchExternalProviderParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<NPISearchResponseDTO>(
      {url: `/api/Claim/SearchExternalProvider`, method: 'get',
        params
    },
      options);
    }
  
export const getApiClaimSearchAdjustmentReasonCode = (
    params?: GetApiClaimSearchAdjustmentReasonCodeParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ClaimAdjustmentReasonCodeDTO>(
      {url: `/api/Claim/SearchAdjustmentReasonCode`, method: 'get',
        params
    },
      options);
    }
  
export const getApiClaimSearchNdcCodes = (
    params?: GetApiClaimSearchNdcCodesParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<NdcCodeDTOPaginatedResponseDTO>(
      {url: `/api/Claim/SearchNdcCodes`, method: 'get',
        params
    },
      options);
    }
  
export const postApiClaimCreateClaimEncounterId = (
    encounterId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ClaimBundleDTO>(
      {url: `/api/Claim/CreateClaim/${encounterId}`, method: 'post'
    },
      options);
    }
  
export const getApiClaimGetClaimByIdClaimId = (
    claimId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ClaimBundleDTO>(
      {url: `/api/Claim/GetClaimById/${claimId}`, method: 'get'
    },
      options);
    }
  
export const deleteApiClaimDeleteClaimByIdClaimId = (
    claimId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/Claim/DeleteClaimById/${claimId}`, method: 'delete'
    },
      options);
    }
  
export const postApiClaimSaveClaim = (
    claimBundleDTO: ClaimBundleDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ClaimBundleDTO>(
      {url: `/api/Claim/SaveClaim`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: claimBundleDTO
    },
      options);
    }
  
export const postApiClaimTransmitClaimToClearinghouseClaimId = (
    claimId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ClaimBundleDTO>(
      {url: `/api/Claim/TransmitClaimToClearinghouse/${claimId}`, method: 'post'
    },
      options);
    }
  
export const postApiClaimTransmitClaimsToClearinghouseByTag = (
    params?: PostApiClaimTransmitClaimsToClearinghouseByTagParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ClaimBundleDTO>(
      {url: `/api/Claim/TransmitClaimsToClearinghouseByTag`, method: 'post',
        params
    },
      options);
    }
  
export const postApiClaimMarkClaimStatus = (
    markClaimStatusDTO: MarkClaimStatusDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ClaimBundleDTO>(
      {url: `/api/Claim/MarkClaimStatus`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: markClaimStatusDTO
    },
      options);
    }
  
export const postApiClaimPrintClaim = (
    printClaimDTO: PrintClaimDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<MultiMediaResponseDTO>(
      {url: `/api/Claim/PrintClaim`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: printClaimDTO
    },
      options);
    }
  
export const getApiClaimGetSplitByEncounterIdEncounterId = (
    encounterId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<SplitClaimsDTO>(
      {url: `/api/Claim/GetSplitByEncounterId/${encounterId}`, method: 'get'
    },
      options);
    }
  
export const postApiClaimSaveSplit = (
    splitClaimsDTO: SplitClaimsDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/Claim/SaveSplit`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: splitClaimsDTO
    },
      options);
    }
  
export const postApiClaimClaimRequestResponseStatusClaimId = (
    claimId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ClaimBundleDTO>(
      {url: `/api/Claim/ClaimRequestResponseStatus/${claimId}`, method: 'post'
    },
      options);
    }
  
export const postApiClaimCreateSecondaryClaim = (
    createSecondaryClaimDTO: CreateSecondaryClaimDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ClaimBundleDTO>(
      {url: `/api/Claim/CreateSecondaryClaim`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createSecondaryClaimDTO
    },
      options);
    }
  
export const postApiCommunicationSendPatientInfoMessage = (
    patientInfoCommuniqueDTO: PatientInfoCommuniqueDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/Communication/SendPatientInfoMessage`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: patientInfoCommuniqueDTO
    },
      options);
    }
  
export const getApiCommunicationGetPatientInfoCommuniqueByTokenToken = (
    token: string,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PatientInfoCommuniqueDTO>(
      {url: `/api/Communication/GetPatientInfoCommuniqueByToken/${token}`, method: 'get'
    },
      options);
    }
  
export const postApiCommunicationSendReminderCommunique = (
    reminderCommuniqueRequestDTO: ReminderCommuniqueRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/Communication/SendReminderCommunique`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: reminderCommuniqueRequestDTO
    },
      options);
    }
  
export const getApiCommunicationGetAppointmentCommuniqueByToken = (
    params?: GetApiCommunicationGetAppointmentCommuniqueByTokenParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ConfirmAppointmentCommuniqueResponseDTO>(
      {url: `/api/Communication/GetAppointmentCommuniqueByToken`, method: 'get',
        params
    },
      options);
    }
  
export const postApiCommunicationRequestAppointmentCommuniqueByPatientInfo = (
    communiqueByPatientInfoRequestDTO: CommuniqueByPatientInfoRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ConfirmAppointmentCommuniqueResponseDTO | void>(
      {url: `/api/Communication/RequestAppointmentCommuniqueByPatientInfo`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: communiqueByPatientInfoRequestDTO
    },
      options);
    }
  
export const getApiCommunicationGetConsentFormPdfPatientIdAnvilTemplateId = (
    patientId: number,
    anvilTemplateId: string,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<MultiMediaResponseDTO>(
      {url: `/api/Communication/GetConsentFormPdf/${patientId}/${anvilTemplateId}`, method: 'get'
    },
      options);
    }
  
export const getApiCommunicationGetProceduralConsentFormsInfoByPatientIdPatientId = (
    patientId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ConsentFormInfoDTO[]>(
      {url: `/api/Communication/GetProceduralConsentFormsInfoByPatientId/${patientId}`, method: 'get'
    },
      options);
    }
  
export const getApiCommunicationGetConsentFormByIdConsentFormId = (
    consentFormId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<OrganizationalConsentFormDTO>(
      {url: `/api/Communication/GetConsentFormById/${consentFormId}`, method: 'get'
    },
      options);
    }
  
export const postApiCommunicationSendConsentForms = (
    consentFormsCommuniqueDTO: ConsentFormsCommuniqueDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/Communication/SendConsentForms`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consentFormsCommuniqueDTO
    },
      options);
    }
  
export const getApiCommunicationGetConsentFormCommuniqueByTokenToken = (
    token: string,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ConsentFormsCommuniqueDTO>(
      {url: `/api/Communication/GetConsentFormCommuniqueByToken/${token}`, method: 'get'
    },
      options);
    }
  
export const getApiCommunicationGenerateSignURLForAppointmentReminderToken = (
    token: string,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<string>(
      {url: `/api/Communication/GenerateSignURLForAppointmentReminder/${token}`, method: 'get'
    },
      options);
    }
  
export const getApiCommunicationGenerateSignURLForConsentFormsToken = (
    token: string,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<string>(
      {url: `/api/Communication/GenerateSignURLForConsentForms/${token}`, method: 'get'
    },
      options);
    }
  
export const getApiCustomServiceCodeSearch = (
    params?: GetApiCustomServiceCodeSearchParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<CustomServiceCodeDTOPaginatedResponseDTO | void>(
      {url: `/api/CustomServiceCode/Search`, method: 'get',
        params
    },
      options);
    }
  
export const postApiCustomServiceCodeToggleFavorite = (
    customServiceCodeFavoriteRequestDTO: CustomServiceCodeFavoriteRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<CustomServiceCodeDTO | void>(
      {url: `/api/CustomServiceCode/ToggleFavorite`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: customServiceCodeFavoriteRequestDTO
    },
      options);
    }
  
export const postApiEFaxSendFax = (
    sendFaxDTO: SendFaxDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/EFax/SendFax`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: sendFaxDTO
    },
      options);
    }
  
export const postApiEFaxResendFax = (
    params?: PostApiEFaxResendFaxParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/EFax/ResendFax`, method: 'post',
        params
    },
      options);
    }
  
export const postApiEFaxGetFaxes = (
    getFaxesRequestDTO: GetFaxesRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<FaxViewDTOPaginatedResponseDTO>(
      {url: `/api/EFax/GetFaxes`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: getFaxesRequestDTO
    },
      options);
    }
  
export const postApiEFaxSetInboundFaxInternalStatus = (
    params?: PostApiEFaxSetInboundFaxInternalStatusParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<InboundFaxDTO>(
      {url: `/api/EFax/SetInboundFaxInternalStatus`, method: 'post',
        params
    },
      options);
    }
  
export const postApiEFaxUploadInboundFileToPatientChart = (
    uploadToPatientChartDTO: UploadToPatientChartDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PatientMultiMediaResponseDTO>(
      {url: `/api/EFax/UploadInboundFileToPatientChart`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: uploadToPatientChartDTO
    },
      options);
    }
  
export const postApiEFaxAddFaxContact = (
    addFaxContactDTO: AddFaxContactDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<FaxContactDTO>(
      {url: `/api/EFax/AddFaxContact`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: addFaxContactDTO
    },
      options);
    }
  
export const getApiEFaxGetFaxContacts = (
    params?: GetApiEFaxGetFaxContactsParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<FaxContactDTOPaginatedResponseDTO>(
      {url: `/api/EFax/GetFaxContacts`, method: 'get',
        params
    },
      options);
    }
  
export const postApiEFaxUpdateFaxContact = (
    faxContactDTO: FaxContactDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<FaxContactDTO>(
      {url: `/api/EFax/UpdateFaxContact`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: faxContactDTO
    },
      options);
    }
  
export const deleteApiEFaxDeleteFaxContact = (
    params?: DeleteApiEFaxDeleteFaxContactParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/EFax/DeleteFaxContact`, method: 'delete',
        params
    },
      options);
    }
  
export const getApiEFaxDownloadFax = (
    params?: GetApiEFaxDownloadFaxParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/EFax/DownloadFax`, method: 'get',
        params
    },
      options);
    }
  
export const postApiEFaxOutboundFaxEventHandler = (
    outboundWebhookData: OutboundWebhookData,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/EFax/OutboundFaxEventHandler`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: outboundWebhookData
    },
      options);
    }
  
export const postApiEFaxInboundFaxEventHandler = (
    inboundWebhookData: InboundWebhookData,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/EFax/InboundFaxEventHandler`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inboundWebhookData
    },
      options);
    }
  
export const getApiEncounterGetEncounterSummariesByDate = (
    params?: GetApiEncounterGetEncounterSummariesByDateParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<EncounterViewModel[]>(
      {url: `/api/Encounter/GetEncounterSummariesByDate`, method: 'get',
        params
    },
      options);
    }
  
export const getApiEncounterGetEncounterDetailsById = (
    params?: GetApiEncounterGetEncounterDetailsByIdParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<EncounterDetailsViewModel>(
      {url: `/api/Encounter/GetEncounterDetailsById`, method: 'get',
        params
    },
      options);
    }
  
export const getApiEncounterGetAppointmentsByDate = (
    params?: GetApiEncounterGetAppointmentsByDateParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<AppointmentDTO[]>(
      {url: `/api/Encounter/GetAppointmentsByDate`, method: 'get',
        params
    },
      options);
    }
  
export const getApiEncounterGetCalendarEventsByDate = (
    params?: GetApiEncounterGetCalendarEventsByDateParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<CalendarEventsResponseDTO>(
      {url: `/api/Encounter/GetCalendarEventsByDate`, method: 'get',
        params
    },
      options);
    }
  
export const getApiEncounterGetAppointmentsByDateRange = (
    params?: GetApiEncounterGetAppointmentsByDateRangeParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<AppointmentDTO[]>(
      {url: `/api/Encounter/GetAppointmentsByDateRange`, method: 'get',
        params
    },
      options);
    }
  
export const getApiEncounterGetCalendarEventsByDateRange = (
    params?: GetApiEncounterGetCalendarEventsByDateRangeParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<CalendarEventsResponseDTO>(
      {url: `/api/Encounter/GetCalendarEventsByDateRange`, method: 'get',
        params
    },
      options);
    }
  
export const getApiEncounterGetAppointmentDetailsByAppointmentIdAppointmentId = (
    appointmentId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<AppointmentDetailsDTO>(
      {url: `/api/Encounter/GetAppointmentDetailsByAppointmentId/${appointmentId}`, method: 'get'
    },
      options);
    }
  
export const postApiEncounterCreateAppointment = (
    createAppointmentRequestDTO: CreateAppointmentRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<CreateAppointmentResponseDTO>(
      {url: `/api/Encounter/CreateAppointment`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createAppointmentRequestDTO
    },
      options);
    }
  
export const deleteApiEncounterDeleteAppointment = (
    deleteAppointmentRequestDTO: DeleteAppointmentRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<AppointmentDTO>(
      {url: `/api/Encounter/DeleteAppointment`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteAppointmentRequestDTO
    },
      options);
    }
  
export const getApiEncounterGetDailyAppointmentBreakdownByDateRange = (
    params?: GetApiEncounterGetDailyAppointmentBreakdownByDateRangeParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<DoctorsAppointmentSummaryDTO[]>(
      {url: `/api/Encounter/GetDailyAppointmentBreakdownByDateRange`, method: 'get',
        params
    },
      options);
    }
  
export const getApiEncounterGetReferringProviderFromLastAppointment = (
    params?: GetApiEncounterGetReferringProviderFromLastAppointmentParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ProviderDTO | void>(
      {url: `/api/Encounter/GetReferringProviderFromLastAppointment`, method: 'get',
        params
    },
      options);
    }
  
export const postApiEncounterSetAppointmentConfirmation = (
    appointmentConfirmationRequestDTO: AppointmentConfirmationRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<AppointmentDTO>(
      {url: `/api/Encounter/SetAppointmentConfirmation`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: appointmentConfirmationRequestDTO
    },
      options);
    }
  
export const postApiEncounterSetAppointmentConfirmationViaVoiceAgent = (
    appointmentConfirmationViaVoiceAgentRequestDTO: AppointmentConfirmationViaVoiceAgentRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<AppointmentDTO>(
      {url: `/api/Encounter/SetAppointmentConfirmationViaVoiceAgent`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: appointmentConfirmationViaVoiceAgentRequestDTO
    },
      options);
    }
  
export const patchApiEncounterModifyAppointmentDetails = (
    modifyAppointmentDetailsRequestDTO: ModifyAppointmentDetailsRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<AppointmentDTO>(
      {url: `/api/Encounter/ModifyAppointmentDetails`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: modifyAppointmentDetailsRequestDTO
    },
      options);
    }
  
export const postApiEncounterModifyAppointmentStatus = (
    modifyAppointmentStatusRequestDTO: ModifyAppointmentStatusRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<AppointmentDTO>(
      {url: `/api/Encounter/ModifyAppointmentStatus`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: modifyAppointmentStatusRequestDTO
    },
      options);
    }
  
export const postApiEncounterModifyAppointmentPaymentType = (
    modifyAppointmentPaymentTypeRequestDTO: ModifyAppointmentPaymentTypeRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<AppointmentDTO>(
      {url: `/api/Encounter/ModifyAppointmentPaymentType`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: modifyAppointmentPaymentTypeRequestDTO
    },
      options);
    }
  
export const postApiEncounterPrintEncounterSchedule = (
    printEncounterScheduleRequestDTO: PrintEncounterScheduleRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<MultiMediaResponseDTO>(
      {url: `/api/Encounter/PrintEncounterSchedule`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: printEncounterScheduleRequestDTO
    },
      options);
    }
  
export const postApiEncounterGetWriteoffsByEncounterId = (
    params?: PostApiEncounterGetWriteoffsByEncounterIdParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<WriteOffByEncounterDTO[]>(
      {url: `/api/Encounter/GetWriteoffsByEncounterId`, method: 'post',
        params
    },
      options);
    }
  
export const getApiERxUserNotificationCounts = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ERxNotificationsCountResponseDTO>(
      {url: `/api/eRx/UserNotificationCounts`, method: 'get'
    },
      options);
    }
  
export const getApiERxUserWebpageURL = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ERxWebpageURLResponseDTO>(
      {url: `/api/eRx/UserWebpageURL`, method: 'get'
    },
      options);
    }
  
export const getApiERxPatientWebpageURL = (
    params?: GetApiERxPatientWebpageURLParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ERxWebpageURLResponseDTO>(
      {url: `/api/eRx/PatientWebpageURL`, method: 'get',
        params
    },
      options);
    }
  
export const getApiERxMedicationSearch = (
    params?: GetApiERxMedicationSearchParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ERxMedicationSearchResponseDTO>(
      {url: `/api/eRx/MedicationSearch`, method: 'get',
        params
    },
      options);
    }
  
export const getApiERxDrugAllergySearch = (
    params?: GetApiERxDrugAllergySearchParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ERxDrugAllergySearchResponseDTO>(
      {url: `/api/eRx/DrugAllergySearch`, method: 'get',
        params
    },
      options);
    }
  
export const getApiERxPharmacySearch = (
    params?: GetApiERxPharmacySearchParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ERxPharmacySearchResponseDTO>(
      {url: `/api/eRx/PharmacySearch`, method: 'get',
        params
    },
      options);
    }
  
export const postApiMultimediaDeleteMultiMedia = (
    multiMediaDeleteDTO: MultiMediaDeleteDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/Multimedia/DeleteMultiMedia`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: multiMediaDeleteDTO
    },
      options);
    }
  
export const postApiMultimediaRequestMultiMedia = (
    multiMediaRequestDTO: MultiMediaRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<MultiMediaResponseDTO>(
      {url: `/api/Multimedia/RequestMultiMedia`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: multiMediaRequestDTO
    },
      options);
    }
  
export const postApiMultimediaPostPatientMultiMedia = (
    patientMultiMediaUploadDTO: PatientMultiMediaUploadDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PatientMultiMediaResponseDTO>(
      {url: `/api/Multimedia/PostPatientMultiMedia`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: patientMultiMediaUploadDTO
    },
      options);
    }
  
export const putApiMultimediaUpdatePatientMultiMedia = (
    patientMultiMediaUpdateDTO: PatientMultiMediaUpdateDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PatientMultiMediaResponseDTO>(
      {url: `/api/Multimedia/UpdatePatientMultiMedia`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: patientMultiMediaUpdateDTO
    },
      options);
    }
  
export const postApiMultimediaPostBillingMultiMedia = (
    billingMultiMediaUploadDTO: BillingMultiMediaUploadDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<BillingMultiMediaResponseDTO>(
      {url: `/api/Multimedia/PostBillingMultiMedia`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: billingMultiMediaUploadDTO
    },
      options);
    }
  
export const postApiMultimediaPostOrgMultiMedia = (
    orgMultiMediaUploadDTO: OrgMultiMediaUploadDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/Multimedia/PostOrgMultiMedia`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: orgMultiMediaUploadDTO
    },
      options);
    }
  
export const getApiMultimediaGetOrgMultiMediaByCategoryOrgIdMediaCategory = (
    orgId: number,
    mediaCategory: string,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<MultiMediaResponseDTO>(
      {url: `/api/Multimedia/GetOrgMultiMediaByCategory/${orgId}/${mediaCategory}`, method: 'get'
    },
      options);
    }
  
export const getApiMultimediaGetBillingMultiMediaByIdBillingMultiMediaIdMediaCategory = (
    billingMultiMediaId: number,
    mediaCategory: string,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<MultiMediaResponse>(
      {url: `/api/Multimedia/GetBillingMultiMediaById/${billingMultiMediaId}/${mediaCategory}`, method: 'get'
    },
      options);
    }
  
export const getApiMultimediaGetPatientMultiMediaByIdPatientMultiMediaId = (
    patientMultiMediaId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<MultiMediaResponseDTO>(
      {url: `/api/Multimedia/GetPatientMultiMediaById/${patientMultiMediaId}`, method: 'get'
    },
      options);
    }
  
export const postApiMultimediaGetPatientMultiMedia = (
    patientMultimediaRequestDTO: PatientMultimediaRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<MultiMediaResponseDTO>(
      {url: `/api/Multimedia/GetPatientMultiMedia`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: patientMultimediaRequestDTO
    },
      options);
    }
  
export const getApiMultimediaGetPatientMultiMediaByCategoryPatientIdMediaCategory = (
    patientId: number,
    mediaCategory: string,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<MultiMediaResponseDTO>(
      {url: `/api/Multimedia/GetPatientMultiMediaByCategory/${patientId}/${mediaCategory}`, method: 'get'
    },
      options);
    }
  
export const getApiMultimediaGetFaxMultimediaByFaxId = (
    params?: GetApiMultimediaGetFaxMultimediaByFaxIdParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<MultiMediaResponseDTO>(
      {url: `/api/Multimedia/GetFaxMultimediaByFaxId`, method: 'get',
        params
    },
      options);
    }
  
export const getApiOrganizationGetOrgByPatientId = (
    params?: GetApiOrganizationGetOrgByPatientIdParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<OrganizationDTO>(
      {url: `/api/Organization/GetOrgByPatientId`, method: 'get',
        params
    },
      options);
    }
  
export const postApiOrganizationGetOrgByIpadBaseIntakeUrl = (
    organizationByIntakeRequestDTO: OrganizationByIntakeRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<OrganizationDTO>(
      {url: `/api/Organization/GetOrgByIpadBaseIntakeUrl`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: organizationByIntakeRequestDTO
    },
      options);
    }
  
export const postApiOrganizationAddConsentForm = (
    organizationalConsentFormsUploadDTO: OrganizationalConsentFormsUploadDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<OrganizationalConsentFormDTO>(
      {url: `/api/Organization/AddConsentForm`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: organizationalConsentFormsUploadDTO
    },
      options);
    }
  
export const postApiOrganizationUpdateConsentForm = (
    organizationalConsentFormDTO: OrganizationalConsentFormDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<OrganizationalConsentFormDTO>(
      {url: `/api/Organization/UpdateConsentForm`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: organizationalConsentFormDTO
    },
      options);
    }
  
export const postApiOrganizationGetConsentFormEditor = (
    consentFormEditorRequestDTO: ConsentFormEditorRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ConsentFormEditorDTO>(
      {url: `/api/Organization/GetConsentFormEditor`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consentFormEditorRequestDTO
    },
      options);
    }
  
export const postApiOrganizationGetAllConsentFormsByOrg = (
    consentFormQueryDTO: ConsentFormQueryDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ConsentFormResponseListDTO>(
      {url: `/api/Organization/GetAllConsentFormsByOrg`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consentFormQueryDTO
    },
      options);
    }
  
export const deleteApiOrganizationDeleteConsentForm = (
    deleteRequestDTO: DeleteRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/Organization/DeleteConsentForm`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteRequestDTO
    },
      options);
    }
  
export const postApiPatientCreateNewPatient = (
    createPatientRequestDTO: CreatePatientRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PatientDTO>(
      {url: `/api/Patient/CreateNewPatient`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createPatientRequestDTO
    },
      options);
    }
  
export const getApiPatientSearchPatients = (
    params?: GetApiPatientSearchPatientsParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<SearchPatientResultDTOPaginatedResponseDTO>(
      {url: `/api/Patient/SearchPatients`, method: 'get',
        params
    },
      options);
    }
  
export const getApiPatientGetPatientByIdId = (
    id: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PatientDTO>(
      {url: `/api/Patient/GetPatientById/${id}`, method: 'get'
    },
      options);
    }
  
export const getApiPatientGetPatientByIdWithPharmacyMedicationAndAllergyId = (
    id: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PatientDTO>(
      {url: `/api/Patient/GetPatientByIdWithPharmacyMedicationAndAllergy/${id}`, method: 'get'
    },
      options);
    }
  
export const getApiPatientGetCountsSummaryPatientId = (
    patientId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PatientCountSummaryDTO>(
      {url: `/api/Patient/GetCountsSummary/${patientId}`, method: 'get'
    },
      options);
    }
  
export const getApiPatientGetPatientMedicalBackgroundId = (
    id: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PatientMedicalBackgroundResponseDTO>(
      {url: `/api/Patient/GetPatientMedicalBackground/${id}`, method: 'get'
    },
      options);
    }
  
export const getApiPatientGetPatientInsurancesByIdId = (
    id: number,
    params?: GetApiPatientGetPatientInsurancesByIdIdParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PatientDTO>(
      {url: `/api/Patient/GetPatientInsurancesById/${id}`, method: 'get',
        params
    },
      options);
    }
  
export const postApiPatientAddPatientMedicalHistory = (
    patientMedicalHistoryRequestDTO: PatientMedicalHistoryRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PatientDTO>(
      {url: `/api/Patient/AddPatientMedicalHistory`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: patientMedicalHistoryRequestDTO
    },
      options);
    }
  
export const postApiPatientAddPatientPharmacyAllergyAndMedication = (
    patientPharmacyAllergyMedicationRequestDTO: PatientPharmacyAllergyMedicationRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ISyncPatientResult>(
      {url: `/api/Patient/AddPatientPharmacyAllergyAndMedication`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: patientPharmacyAllergyMedicationRequestDTO
    },
      options);
    }
  
export const patchApiPatientUpdatePatient = (
    patientDTO: PatientDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PatientDTO>(
      {url: `/api/Patient/UpdatePatient`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patientDTO
    },
      options);
    }
  
export const postApiPatientAddUpdateOrDeletePatientInsurances = (
    patientInsurancesRequestDTO: PatientInsurancesRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PatientDTO>(
      {url: `/api/Patient/AddUpdateOrDeletePatientInsurances`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: patientInsurancesRequestDTO
    },
      options);
    }
  
export const postApiPatientAddPatientInsurance = (
    patientInsurancesRequestDTO: PatientInsurancesRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PatientDTO>(
      {url: `/api/Patient/AddPatientInsurance`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: patientInsurancesRequestDTO
    },
      options);
    }
  
export const postApiPatientAddReasonForVisit = (
    reasonForVisitDTO: ReasonForVisitDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PatientDTO>(
      {url: `/api/Patient/AddReasonForVisit`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: reasonForVisitDTO
    },
      options);
    }
  
export const getApiPatientGetSymptomsFromImmediatePastVisitPatientId = (
    patientId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PatientWithHPIAttributesDTO>(
      {url: `/api/Patient/GetSymptomsFromImmediatePastVisit/${patientId}`, method: 'get'
    },
      options);
    }
  
export const getApiPatientGetConsentFormByPatientIdPatientId = (
    patientId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<OrganizationalConsentFormsResponseDTO>(
      {url: `/api/Patient/GetConsentFormByPatientId/${patientId}`, method: 'get'
    },
      options);
    }
  
export const postApiPatientSubmitGeneralConsentForm = (
    generalConsentByPatientDTO: GeneralConsentByPatientDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/Patient/SubmitGeneralConsentForm`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: generalConsentByPatientDTO
    },
      options);
    }
  
export const postApiPatientSubmitDosespotConsentForm = (
    dosespotConsentByPatientDTO: DosespotConsentByPatientDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/Patient/SubmitDosespotConsentForm`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: dosespotConsentByPatientDTO
    },
      options);
    }
  
export const postApiPatientSubmitProceduralConsentForm = (
    proceduralConsentByPatientDTO: ProceduralConsentByPatientDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/Patient/SubmitProceduralConsentForm`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: proceduralConsentByPatientDTO
    },
      options);
    }
  
export const postApiPatientVerifyPatientDOBById = (
    patientDOBDTO: PatientDOBDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None | void>(
      {url: `/api/Patient/VerifyPatientDOBById`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: patientDOBDTO
    },
      options);
    }
  
export const getApiPatientGetPatientLedgerPatientId = (
    patientId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<EncounterLedger[]>(
      {url: `/api/Patient/GetPatientLedger/${patientId}`, method: 'get'
    },
      options);
    }
  
export const getApiPatientPrintPatientFaceSheet = (
    params?: GetApiPatientPrintPatientFaceSheetParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<MultiMediaResponseDTO>(
      {url: `/api/Patient/PrintPatientFaceSheet`, method: 'get',
        params
    },
      options);
    }
  
export const getApiProviderAvailabilityGetProviderAvailability = (
    params?: GetApiProviderAvailabilityGetProviderAvailabilityParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ProviderAvailabilityDTO>(
      {url: `/api/ProviderAvailability/GetProviderAvailability`, method: 'get',
        params
    },
      options);
    }
  
export const postApiProviderAvailabilityAddOrUpdate = (
    providerAvailabilityDTO: NonReadonly<ProviderAvailabilityDTO>,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<SaveAvailabilityResponse>(
      {url: `/api/ProviderAvailability/AddOrUpdate`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: providerAvailabilityDTO
    },
      options);
    }
  
export const deleteApiProviderAvailabilityDelete = (
    params?: DeleteApiProviderAvailabilityDeleteParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<DeleteCalendarSlotResponseDTO>(
      {url: `/api/ProviderAvailability/Delete`, method: 'delete',
        params
    },
      options);
    }
  
export const postApiProviderAvailabilityDeletePartially = (
    deletePartialRequest: DeletePartialRequest,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<DeleteCalendarSlotResponseDTO>(
      {url: `/api/ProviderAvailability/DeletePartially`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: deletePartialRequest
    },
      options);
    }
  
export const getApiReportGetReportSettings = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ReportSettingsResponseDTO>(
      {url: `/api/Report/GetReportSettings`, method: 'get'
    },
      options);
    }
  
export const postApiReportTriggerReport = (
    reportRequestDTOBase: ReportRequestDTOBase,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<TriggerReportResponseDTO>(
      {url: `/api/Report/TriggerReport`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: reportRequestDTOBase
    },
      options);
    }
  
export const postApiReportGetReportResults = (
    reportRequestDTO: ReportRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ReportResponseDTO>(
      {url: `/api/Report/GetReportResults`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: reportRequestDTO
    },
      options);
    }
  
export const getApiReportDownloadReport = (
    params?: GetApiReportDownloadReportParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<FileResultDTO>(
      {url: `/api/Report/DownloadReport`, method: 'get',
        params
    },
      options);
    }
  
export const postApiReportDeleteReport = (
    params?: PostApiReportDeleteReportParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<FileResultDTO>(
      {url: `/api/Report/DeleteReport`, method: 'post',
        params
    },
      options);
    }
  
export const postApiReportHandleReportComplete = (
    reportCompleteDTO: ReportCompleteDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ReportResponseDTO>(
      {url: `/api/Report/HandleReportComplete`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: reportCompleteDTO
    },
      options);
    }
  
export const postApiScheduledJobRunBatchSyncMedicalHistoryForOrgOrgId = (
    orgId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/ScheduledJob/RunBatchSyncMedicalHistoryForOrg/${orgId}`, method: 'post'
    },
      options);
    }
  
export const postApiScheduledJobRunOldPatientDosespotSync = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/ScheduledJob/RunOldPatientDosespotSync`, method: 'post'
    },
      options);
    }
  
export const getApiSettingsGetIntakeFormSettings = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<SettingsDTO>(
      {url: `/api/Settings/GetIntakeFormSettings`, method: 'get'
    },
      options);
    }
  
export const getApiSettingsGetClaimSettings = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<SettingsDTO>(
      {url: `/api/Settings/GetClaimSettings`, method: 'get'
    },
      options);
    }
  
export const getApiSettingsGetChargeFilterSettings = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<SettingsDTO>(
      {url: `/api/Settings/GetChargeFilterSettings`, method: 'get'
    },
      options);
    }
  
export const getApiSettingsGetPatientPaymentFilterSettings = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<SettingsDTO>(
      {url: `/api/Settings/GetPatientPaymentFilterSettings`, method: 'get'
    },
      options);
    }
  
export const getApiSettingsGetPatientMultimediaFilterSettings = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<SettingsDTO>(
      {url: `/api/Settings/GetPatientMultimediaFilterSettings`, method: 'get'
    },
      options);
    }
  
export const getApiSettingsGetAppointmentSettings = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<SettingsDTO>(
      {url: `/api/Settings/GetAppointmentSettings`, method: 'get'
    },
      options);
    }
  
export const getApiSettingsGetAppointmentTypeSettings = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<AppointmentTypeSettingDTO[]>(
      {url: `/api/Settings/GetAppointmentTypeSettings`, method: 'get'
    },
      options);
    }
  
export const postApiSettingsAddAppointmentTypeSetting = (
    appointmentTypeSettingDTO: AppointmentTypeSettingDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<AppointmentTypeSettingDTO>(
      {url: `/api/Settings/AddAppointmentTypeSetting`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: appointmentTypeSettingDTO
    },
      options);
    }
  
export const postApiSettingsUpdateAppointmentTypeSetting = (
    appointmentTypeSettingDTO: AppointmentTypeSettingDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<AppointmentTypeSettingDTO>(
      {url: `/api/Settings/UpdateAppointmentTypeSetting`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: appointmentTypeSettingDTO
    },
      options);
    }
  
export const getApiSettingsGetToDoSettings = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<SettingsDTO>(
      {url: `/api/Settings/GetToDoSettings`, method: 'get'
    },
      options);
    }
  
export const getApiSettingsGetWriteOffSettings = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<SettingsDTO>(
      {url: `/api/Settings/GetWriteOffSettings`, method: 'get'
    },
      options);
    }
  
export const getApiSettingsGetEFaxFilterSettings = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<SettingsDTO>(
      {url: `/api/Settings/GetEFaxFilterSettings`, method: 'get'
    },
      options);
    }
  
export const getApiSettingsGetUserManagementSettings = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<UserManagementSettingsDTO>(
      {url: `/api/Settings/GetUserManagementSettings`, method: 'get'
    },
      options);
    }
  
export const getApiSettingsGetOrCreateUserSettings = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<UserSettingDTO>(
      {url: `/api/Settings/GetOrCreateUserSettings`, method: 'get'
    },
      options);
    }
  
export const postApiSettingsCreateOrUpdateUserSettings = (
    userSettingDTO: UserSettingDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<UserSettingDTO>(
      {url: `/api/Settings/CreateOrUpdateUserSettings`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: userSettingDTO
    },
      options);
    }
  
export const getApiSettingsGetPayerEntryConfigsByType = (
    params?: GetApiSettingsGetPayerEntryConfigsByTypeParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PayerEntryConfigDTOPaginatedResponseDTO>(
      {url: `/api/Settings/GetPayerEntryConfigsByType`, method: 'get',
        params
    },
      options);
    }
  
export const postApiSettingsAddPaperPayerEntry = (
    createPaperPayerRequestDTO: CreatePaperPayerRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PaperPayerEntryDTO>(
      {url: `/api/Settings/AddPaperPayerEntry`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createPaperPayerRequestDTO
    },
      options);
    }
  
export const putApiSettingsUpdatePaperPayerEntry = (
    updatePaperPayerRequestDTO: UpdatePaperPayerRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PaperPayerEntryDTO>(
      {url: `/api/Settings/UpdatePaperPayerEntry`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updatePaperPayerRequestDTO
    },
      options);
    }
  
export const deleteApiSettingsDeletePaperPayerEntry = (
    params?: DeleteApiSettingsDeletePaperPayerEntryParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/Settings/DeletePaperPayerEntry`, method: 'delete',
        params
    },
      options);
    }
  
export const postApiSettingsAddElectronicPayerEntryConfig = (
    payerEntryConfigDTO: PayerEntryConfigDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PayerEntryConfigDTO>(
      {url: `/api/Settings/AddElectronicPayerEntryConfig`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: payerEntryConfigDTO
    },
      options);
    }
  
export const putApiSettingsUpdateElectronicPayerEntryConfig = (
    payerEntryConfigDTO: PayerEntryConfigDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PayerEntryConfigDTO>(
      {url: `/api/Settings/UpdateElectronicPayerEntryConfig`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: payerEntryConfigDTO
    },
      options);
    }
  
export const deleteApiSettingsDeleteElectronicPayerEntryConfig = (
    params?: DeleteApiSettingsDeleteElectronicPayerEntryConfigParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/Settings/DeleteElectronicPayerEntryConfig`, method: 'delete',
        params
    },
      options);
    }
  
export const getApiSettingsMigratePaperPayers = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/Settings/MigratePaperPayers`, method: 'get'
    },
      options);
    }
  
export const getApiSquareAuthorizationGetAuthorizationUrl = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<string>(
      {url: `/api/SquareAuthorization/GetAuthorizationUrl`, method: 'get'
    },
      options);
    }
  
export const getApiSquareAuthorizationAuthorizationRedirect = (
    params?: GetApiSquareAuthorizationAuthorizationRedirectParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/SquareAuthorization/AuthorizationRedirect`, method: 'get',
        params
    },
      options);
    }
  
export const postApiSquareAuthorizationRetrieveTokenStatus = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/SquareAuthorization/RetrieveTokenStatus`, method: 'post'
    },
      options);
    }
  
export const postApiSquareAuthorizationRefreshToken = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/SquareAuthorization/RefreshToken`, method: 'post'
    },
      options);
    }
  
export const postApiSquareAuthorizationRevokeToken = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/SquareAuthorization/RevokeToken`, method: 'post'
    },
      options);
    }
  
export const postApiSquarePaymentsHandleEventNotification = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/SquarePayments/HandleEventNotification`, method: 'post'
    },
      options);
    }
  
export const getApiSquarePaymentsListLocations = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/SquarePayments/ListLocations`, method: 'get'
    },
      options);
    }
  
export const getApiSquarePaymentsGetDeviceByDeviceId = (
    params?: GetApiSquarePaymentsGetDeviceByDeviceIdParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/SquarePayments/GetDeviceByDeviceId`, method: 'get',
        params
    },
      options);
    }
  
export const getApiSquarePaymentsListDevices = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/SquarePayments/ListDevices`, method: 'get'
    },
      options);
    }
  
export const postApiSquarePaymentsCreateDeviceCodeForLocation = (
    params?: PostApiSquarePaymentsCreateDeviceCodeForLocationParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/SquarePayments/CreateDeviceCodeForLocation`, method: 'post',
        params
    },
      options);
    }
  
export const postApiSquarePaymentsCreateTerminalCheckout = (
    params?: PostApiSquarePaymentsCreateTerminalCheckoutParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/SquarePayments/CreateTerminalCheckout`, method: 'post',
        params
    },
      options);
    }
  
export const postApiSquarePaymentsGetPayment = (
    getPaymentRequest: GetPaymentRequest,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PaymentDTO>(
      {url: `/api/SquarePayments/GetPayment`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: getPaymentRequest
    },
      options);
    }
  
export const postApiSquarePaymentsCancelTerminalCheckout = (
    cancelTerminalCheckoutRequest: CancelTerminalCheckoutRequest,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<CancelledTerminalCheckoutDTO>(
      {url: `/api/SquarePayments/CancelTerminalCheckout`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: cancelTerminalCheckoutRequest
    },
      options);
    }
  
export const postApiSquarePaymentsCreateQuickPayCheckout = (
    params?: PostApiSquarePaymentsCreateQuickPayCheckoutParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/SquarePayments/CreateQuickPayCheckout`, method: 'post',
        params
    },
      options);
    }
  
export const getApiStickyGetAllStickiesByPatientId = (
    params?: GetApiStickyGetAllStickiesByPatientIdParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<StickyDTO[]>(
      {url: `/api/Sticky/GetAllStickiesByPatientId`, method: 'get',
        params
    },
      options);
    }
  
export const getApiStickyGetStickyById = (
    params?: GetApiStickyGetStickyByIdParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<StickyDTO>(
      {url: `/api/Sticky/GetStickyById`, method: 'get',
        params
    },
      options);
    }
  
export const postApiStickyCreateSticky = (
    createStickyRequestDTO: CreateStickyRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<StickyDTO>(
      {url: `/api/Sticky/CreateSticky`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createStickyRequestDTO
    },
      options);
    }
  
export const postApiStickyUpdateSticky = (
    updateStickyRequestDTO: UpdateStickyRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<StickyDTO>(
      {url: `/api/Sticky/UpdateSticky`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: updateStickyRequestDTO
    },
      options);
    }
  
export const deleteApiStickyDeleteSticky = (
    deleteStickyRequestDTO: DeleteStickyRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<StickyDTO>(
      {url: `/api/Sticky/DeleteSticky`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteStickyRequestDTO
    },
      options);
    }
  
export const postApiTemplateSaveSectionTemplate = (
    saveSectionTemplateRequestDTO: SaveSectionTemplateRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<SectionTemplateDTO>(
      {url: `/api/Template/SaveSectionTemplate`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: saveSectionTemplateRequestDTO
    },
      options);
    }
  
export const getApiTemplateRetrieveSectionTemplate = (
    params?: GetApiTemplateRetrieveSectionTemplateParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<string>(
      {url: `/api/Template/RetrieveSectionTemplate`, method: 'get',
        params
    },
      options);
    }
  
export const deleteApiTemplateDeleteSectionTemplate = (
    deleteSectionTemplateRequestDTO: DeleteSectionTemplateRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<SectionTemplateDTO>(
      {url: `/api/Template/DeleteSectionTemplate`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteSectionTemplateRequestDTO
    },
      options);
    }
  
export const getApiTemplateGetSectionTemplateList = (
    params?: GetApiTemplateGetSectionTemplateListParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<TemplateListItemDTOPaginatedResponseDTO>(
      {url: `/api/Template/GetSectionTemplateList`, method: 'get',
        params
    },
      options);
    }
  
export const getApiTemplateRetrieveSectionFromPriorVisitNotePatientIdSectionNameCurrentVisitNoteId = (
    patientId: number,
    sectionName: string,
    currentVisitNoteId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<string>(
      {url: `/api/Template/RetrieveSectionFromPriorVisitNote/${patientId}/${sectionName}/${currentVisitNoteId}`, method: 'get'
    },
      options);
    }
  
export const postApiTemplateSaveMasterTemplate = (
    saveMasterTemplateRequestDTO: SaveMasterTemplateRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<MasterTemplateDTO>(
      {url: `/api/Template/SaveMasterTemplate`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: saveMasterTemplateRequestDTO
    },
      options);
    }
  
export const getApiTemplateRetrieveMasterTemplate = (
    params?: GetApiTemplateRetrieveMasterTemplateParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<MasterTemplateDTO>(
      {url: `/api/Template/RetrieveMasterTemplate`, method: 'get',
        params
    },
      options);
    }
  
export const deleteApiTemplateDeleteMasterTemplate = (
    deleteMasterTemplateRequestDTO: DeleteMasterTemplateRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<MasterTemplateDTO>(
      {url: `/api/Template/DeleteMasterTemplate`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteMasterTemplateRequestDTO
    },
      options);
    }
  
export const getApiTemplateGetMasterTemplateList = (
    params?: GetApiTemplateGetMasterTemplateListParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<TemplateListItemDTOPaginatedResponseDTO>(
      {url: `/api/Template/GetMasterTemplateList`, method: 'get',
        params
    },
      options);
    }
  
export const getApiTemplateGetFieldList = (
    params?: GetApiTemplateGetFieldListParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<TemplateFieldDTOPaginatedResponseDTO>(
      {url: `/api/Template/GetFieldList`, method: 'get',
        params
    },
      options);
    }
  
export const getApiTemplateGetFieldFieldId = (
    fieldId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<TemplateFieldDTO>(
      {url: `/api/Template/GetField/${fieldId}`, method: 'get'
    },
      options);
    }
  
export const postApiTemplateAddField = (
    templateFieldRequestDTO: TemplateFieldRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<TemplateFieldDTO>(
      {url: `/api/Template/AddField`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: templateFieldRequestDTO
    },
      options);
    }
  
export const putApiTemplateEditField = (
    templateFieldRequestDTO: TemplateFieldRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<TemplateFieldDTO>(
      {url: `/api/Template/EditField`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: templateFieldRequestDTO
    },
      options);
    }
  
export const deleteApiTemplateDeleteField = (
    templateFieldDTO: TemplateFieldDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/Template/DeleteField`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: templateFieldDTO
    },
      options);
    }
  
export const postApiToDoRequestToDoList = (
    toDoRequestDTO: ToDoRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ToDoPaginatedCollectionDTO>(
      {url: `/api/ToDo/RequestToDoList`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: toDoRequestDTO
    },
      options);
    }
  
export const getApiToDoGetToDoById = (
    params?: GetApiToDoGetToDoByIdParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ToDoResponseWithReferencedObjectsDTO | void>(
      {url: `/api/ToDo/GetToDoById`, method: 'get',
        params
    },
      options);
    }
  
export const getApiToDoGetCommentsByToDoId = (
    params?: GetApiToDoGetCommentsByToDoIdParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ToDoCommentsCollectionDTO | void>(
      {url: `/api/ToDo/GetCommentsByToDoId`, method: 'get',
        params
    },
      options);
    }
  
export const postApiToDoCreateToDo = (
    createToDoRequestDTO: CreateToDoRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ToDoResponseDTO | void>(
      {url: `/api/ToDo/CreateToDo`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createToDoRequestDTO
    },
      options);
    }
  
export const postApiToDoCommentOnToDo = (
    toDoCommentRequestDTO: ToDoCommentRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ToDoCommentDTO | void>(
      {url: `/api/ToDo/CommentOnToDo`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: toDoCommentRequestDTO
    },
      options);
    }
  
export const putApiToDoUpdateToDo = (
    toDoUpdateRequestDTO: ToDoUpdateRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ToDoResponseDTO | void>(
      {url: `/api/ToDo/UpdateToDo`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: toDoUpdateRequestDTO
    },
      options);
    }
  
export const getApiToDoGetUnreadToDoCountOfUser = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ToDoCountResponseDTO | void>(
      {url: `/api/ToDo/GetUnreadToDoCountOfUser`, method: 'get'
    },
      options);
    }
  
export const getApiToDoGetFaxToDos = (
    params?: GetApiToDoGetFaxToDosParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ToDoPaginatedCollectionDTO>(
      {url: `/api/ToDo/GetFaxToDos`, method: 'get',
        params
    },
      options);
    }
  
export const getApiUserGetAllUsersByTitleTitle = (
    title: string,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<UserDTO[]>(
      {url: `/api/User/GetAllUsersByTitle/${title}`, method: 'get'
    },
      options);
    }
  
export const getApiUserGetLoggedInUserInfo = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<LoggedInUserInfoDTO>(
      {url: `/api/User/GetLoggedInUserInfo`, method: 'get'
    },
      options);
    }
  
export const getApiUserGetUsers = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<UserDTO[]>(
      {url: `/api/User/GetUsers`, method: 'get'
    },
      options);
    }
  
export const postApiUserCreateUser = (
    createUserRequestDTO: CreateUserRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<UserDTO>(
      {url: `/api/User/CreateUser`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createUserRequestDTO
    },
      options);
    }
  
export const postApiUserUpdateUser = (
    updateUserRequestDTO: UpdateUserRequestDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<UserDTO>(
      {url: `/api/User/UpdateUser`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: updateUserRequestDTO
    },
      options);
    }
  
export const postApiUserAuthenticateCurrentUserInNabla = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<NablaAuthenticationResponseDTO>(
      {url: `/api/User/AuthenticateCurrentUserInNabla`, method: 'post'
    },
      options);
    }
  
export const getApiVisitNoteGetAllVisitNotesByPatientIdPatientId = (
    patientId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<VisitNoteDTO[]>(
      {url: `/api/VisitNote/GetAllVisitNotesByPatientId/${patientId}`, method: 'get'
    },
      options);
    }
  
export const getApiVisitNoteGetUpcomingAppointmentsByPatientIdPatientId = (
    patientId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PatientVisitDTO[]>(
      {url: `/api/VisitNote/GetUpcomingAppointmentsByPatientId/${patientId}`, method: 'get'
    },
      options);
    }
  
export const getApiVisitNoteGetHistoricalVisitsByPatientIdPatientId = (
    patientId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<PatientVisitDTO[]>(
      {url: `/api/VisitNote/GetHistoricalVisitsByPatientId/${patientId}`, method: 'get'
    },
      options);
    }
  
export const getApiVisitNoteGetVisitNoteByEncounterIdEncounterId = (
    encounterId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<VisitNotePageResponseDTO>(
      {url: `/api/VisitNote/GetVisitNoteByEncounterId/${encounterId}`, method: 'get'
    },
      options);
    }
  
export const getApiVisitNoteGetAppointmentRelatedInfoByVisitNoteIdVisitNoteId = (
    visitNoteId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<AppointmentInfoForVisitNoteDTO>(
      {url: `/api/VisitNote/GetAppointmentRelatedInfoByVisitNoteId/${visitNoteId}`, method: 'get'
    },
      options);
    }
  
export const getApiVisitNoteGetUserFavoriteMedicalCodes = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<UserFavoritedMedicalCodesResponseDTO>(
      {url: `/api/VisitNote/GetUserFavoriteMedicalCodes`, method: 'get'
    },
      options);
    }
  
export const postApiVisitNoteSaveVisitNote = (
    visitNoteDTO: VisitNoteDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<VisitNoteDTO>(
      {url: `/api/VisitNote/SaveVisitNote`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: visitNoteDTO
    },
      options);
    }
  
export const getApiVisitNoteRetrieveBillingAssessmentCodesFromPriorVisitNotePatientIdCurrentVisitNoteId = (
    patientId: number,
    currentVisitNoteId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<VisitNoteBillingAssessmentCodeDTO[]>(
      {url: `/api/VisitNote/RetrieveBillingAssessmentCodesFromPriorVisitNote/${patientId}/${currentVisitNoteId}`, method: 'get'
    },
      options);
    }
  
export const getApiVisitNoteRetrieveBillingCodesFromPriorVisitNotePatientIdCurrentVisitNoteId = (
    patientId: number,
    currentVisitNoteId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<VisitNoteBillingCodeDTO[]>(
      {url: `/api/VisitNote/RetrieveBillingCodesFromPriorVisitNote/${patientId}/${currentVisitNoteId}`, method: 'get'
    },
      options);
    }
  
export const getApiVisitNoteRetrieveCustomServiceCodesFromPriorVisitNotePatientIdCurrentVisitNoteId = (
    patientId: number,
    currentVisitNoteId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<VisitNoteBillingCustomServiceCodeDTO[]>(
      {url: `/api/VisitNote/RetrieveCustomServiceCodesFromPriorVisitNote/${patientId}/${currentVisitNoteId}`, method: 'get'
    },
      options);
    }
  
export const getApiVisitNoteGetHPIInParagraphForm = (
    params?: GetApiVisitNoteGetHPIInParagraphFormParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<string>(
      {url: `/api/VisitNote/GetHPIInParagraphForm`, method: 'get',
        params
    },
      options);
    }
  
export const getApiVisitNoteGetAICodeSuggestionsEncounterIdCodeType = (
    encounterId: number,
    codeType: string,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<CPTCodeSuggestionsDTO>(
      {url: `/api/VisitNote/GetAICodeSuggestions/${encounterId}/${codeType}`, method: 'get'
    },
      options);
    }
  
export const putApiVisitNoteAddAddendum = (
    addendumDTO: AddendumDTO,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/VisitNote/AddAddendum`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: addendumDTO
    },
      options);
    }
  
export const getApiVisitNoteGetAddendumFinalizedVisitNoteId = (
    finalizedVisitNoteId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<AddendumDTO>(
      {url: `/api/VisitNote/GetAddendum/${finalizedVisitNoteId}`, method: 'get'
    },
      options);
    }
  
export const getApiVisitNoteRetrieveLastVisitNote = (
    params?: GetApiVisitNoteRetrieveLastVisitNoteParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/VisitNote/RetrieveLastVisitNote`, method: 'get',
        params
    },
      options);
    }
  
export const getApiVisitNoteGetPastVisitNotesForPatient = (
    params?: GetApiVisitNoteGetPastVisitNotesForPatientParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<VisitNoteSummaryDTO[]>(
      {url: `/api/VisitNote/GetPastVisitNotesForPatient`, method: 'get',
        params
    },
      options);
    }
  
export const getApiVisitNoteGetSpecificVisitNoteForPatient = (
    params?: GetApiVisitNoteGetSpecificVisitNoteForPatientParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<VisitNoteDTO>(
      {url: `/api/VisitNote/GetSpecificVisitNoteForPatient`, method: 'get',
        params
    },
      options);
    }
  
export const postApiVisitNoteUnFinalize = (
    params?: PostApiVisitNoteUnFinalizeParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<VisitNoteDTO>(
      {url: `/api/VisitNote/UnFinalize`, method: 'post',
        params
    },
      options);
    }
  
export const postApiVisitNotePrintVisitNotePdfEncounterId = (
    encounterId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<MultiMediaResponseDTO>(
      {url: `/api/VisitNote/PrintVisitNotePdf/${encounterId}`, method: 'post'
    },
      options);
    }
  
export const getApiVisitNoteGetMipsSuggestionVisitNoteId = (
    visitNoteId: number,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<MipsResponseDTO>(
      {url: `/api/VisitNote/GetMipsSuggestion/${visitNoteId}`, method: 'get'
    },
      options);
    }
  
export const getApiVisitNoteGetMipsSubmissionData = (
    params?: GetApiVisitNoteGetMipsSubmissionDataParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<string>(
      {url: `/api/VisitNote/GetMipsSubmissionData`, method: 'get',
        params
    },
      options);
    }
  
export const postCallPatientForAppointmentConfirmation = (
    params?: PostCallPatientForAppointmentConfirmationParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<string>(
      {url: `/CallPatientForAppointmentConfirmation`, method: 'post',
        params
    },
      options);
    }
  
export const postCallForAppointmentConfirmation = (
    params?: PostCallForAppointmentConfirmationParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<string>(
      {url: `/CallForAppointmentConfirmation`, method: 'post',
        params
    },
      options);
    }
  
export const getApiVoiceAssistantGetSession = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<void>(
      {url: `/api/VoiceAssistant/GetSession`, method: 'get'
    },
      options);
    }
  
export const getApiVoiceAssistantInitializeEmbeddings = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/VoiceAssistant/InitializeEmbeddings`, method: 'get'
    },
      options);
    }
  
export const getApiVoiceAssistantInitializeAppointmentEmbeddings = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/VoiceAssistant/InitializeAppointmentEmbeddings`, method: 'get'
    },
      options);
    }
  
export const getApiVoiceAssistantInitializePatientEmbeddings = (
    
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<None>(
      {url: `/api/VoiceAssistant/InitializePatientEmbeddings`, method: 'get'
    },
      options);
    }
  
export const getApiVoiceAssistantGetQueryAnswer = (
    params?: GetApiVoiceAssistantGetQueryAnswerParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<ChatMessageContent>(
      {url: `/api/VoiceAssistant/GetQueryAnswer`, method: 'get',
        params
    },
      options);
    }
  
export const getApiVoiceAssistantGetContextFromPopulate = (
    params?: GetApiVoiceAssistantGetContextFromPopulateParams,
 options?: SecondParameter<typeof sendRequest>,) => {
      return sendRequest<string>(
      {url: `/api/VoiceAssistant/GetContextFromPopulate`, method: 'get',
        params
    },
      options);
    }
  
export type PostApiAnvilHandleActionResult = NonNullable<Awaited<ReturnType<typeof postApiAnvilHandleAction>>>
export type PostApiBillingGetPatientPaymentsResult = NonNullable<Awaited<ReturnType<typeof postApiBillingGetPatientPayments>>>
export type PostApiBillingCreatePatientPaymentResult = NonNullable<Awaited<ReturnType<typeof postApiBillingCreatePatientPayment>>>
export type PostApiBillingSendBillForOnlinePaymentResult = NonNullable<Awaited<ReturnType<typeof postApiBillingSendBillForOnlinePayment>>>
export type PostApiBillingAddOrUpdateWriteOffsResult = NonNullable<Awaited<ReturnType<typeof postApiBillingAddOrUpdateWriteOffs>>>
export type GetApiBillingGetInsurancePaymentsResult = NonNullable<Awaited<ReturnType<typeof getApiBillingGetInsurancePayments>>>
export type PostApiBillingCreateInsurancePaymentResult = NonNullable<Awaited<ReturnType<typeof postApiBillingCreateInsurancePayment>>>
export type PostApiBillingModifyInsurancePaymentResult = NonNullable<Awaited<ReturnType<typeof postApiBillingModifyInsurancePayment>>>
export type DeleteApiBillingDeleteInsurancePaymentResult = NonNullable<Awaited<ReturnType<typeof deleteApiBillingDeleteInsurancePayment>>>
export type GetApiBillingGetEOBsResult = NonNullable<Awaited<ReturnType<typeof getApiBillingGetEOBs>>>
export type GetApiBillingGetEOBResult = NonNullable<Awaited<ReturnType<typeof getApiBillingGetEOB>>>
export type PostApiBillingCreateEOBWithInsurancePaymentResult = NonNullable<Awaited<ReturnType<typeof postApiBillingCreateEOBWithInsurancePayment>>>
export type PostApiBillingModifyEOBResult = NonNullable<Awaited<ReturnType<typeof postApiBillingModifyEOB>>>
export type DeleteApiBillingDeleteEOBResult = NonNullable<Awaited<ReturnType<typeof deleteApiBillingDeleteEOB>>>
export type GetApiBillingGetBillingSummaryDataByEncounterIdEncounterIdResult = NonNullable<Awaited<ReturnType<typeof getApiBillingGetBillingSummaryDataByEncounterIdEncounterId>>>
export type GetApiBillingGetBillingSummaryDataByPatientIdPatientIdResult = NonNullable<Awaited<ReturnType<typeof getApiBillingGetBillingSummaryDataByPatientIdPatientId>>>
export type GetApiBillingGetAutoAllocationPreviewPatientIdResult = NonNullable<Awaited<ReturnType<typeof getApiBillingGetAutoAllocationPreviewPatientId>>>
export type PostApiBillingCreateAutoAllocationPatientIdResult = NonNullable<Awaited<ReturnType<typeof postApiBillingCreateAutoAllocationPatientId>>>
export type PostApiBillingCreateAllocationResult = NonNullable<Awaited<ReturnType<typeof postApiBillingCreateAllocation>>>
export type PostApiBillingCreateDepositResult = NonNullable<Awaited<ReturnType<typeof postApiBillingCreateDeposit>>>
export type GetApiBillingGetEncounterBillingDataResult = NonNullable<Awaited<ReturnType<typeof getApiBillingGetEncounterBillingData>>>
export type GetApiBillingGetUnpaidEncounterBillingDataResult = NonNullable<Awaited<ReturnType<typeof getApiBillingGetUnpaidEncounterBillingData>>>
export type GetApiBillingGetEOBByPatientPatientIdResult = NonNullable<Awaited<ReturnType<typeof getApiBillingGetEOBByPatientPatientId>>>
export type PostApiBillingGetPatientStatementResult = NonNullable<Awaited<ReturnType<typeof postApiBillingGetPatientStatement>>>
export type PostApiBillingPrintStatementPdfResult = NonNullable<Awaited<ReturnType<typeof postApiBillingPrintStatementPdf>>>
export type PostApiBillingSendStatementPdfResult = NonNullable<Awaited<ReturnType<typeof postApiBillingSendStatementPdf>>>
export type PostApiBillingGenerateBulkPatientStatementResult = NonNullable<Awaited<ReturnType<typeof postApiBillingGenerateBulkPatientStatement>>>
export type PostApiBillingGetBulkPatientStatementsResult = NonNullable<Awaited<ReturnType<typeof postApiBillingGetBulkPatientStatements>>>
export type GetApiBillingDownloadBulkPatientStatementResult = NonNullable<Awaited<ReturnType<typeof getApiBillingDownloadBulkPatientStatement>>>
export type PostApiBillingHandleBulkPatientStatementCompleteResult = NonNullable<Awaited<ReturnType<typeof postApiBillingHandleBulkPatientStatementComplete>>>
export type PostApiBillingPrintInvoicePdfResult = NonNullable<Awaited<ReturnType<typeof postApiBillingPrintInvoicePdf>>>
export type GetApiBillingGetStatementLastSentInfoResult = NonNullable<Awaited<ReturnType<typeof getApiBillingGetStatementLastSentInfo>>>
export type GetApiBillingGetInvoiceSummaryResult = NonNullable<Awaited<ReturnType<typeof getApiBillingGetInvoiceSummary>>>
export type GetApiBillingSyncFinancialsResult = NonNullable<Awaited<ReturnType<typeof getApiBillingSyncFinancials>>>
export type GetApiBillingCodeSearchResult = NonNullable<Awaited<ReturnType<typeof getApiBillingCodeSearch>>>
export type GetApiBillingCodeGetFavoritesForUserResult = NonNullable<Awaited<ReturnType<typeof getApiBillingCodeGetFavoritesForUser>>>
export type GetApiBillingCodeGetAliasesForUserResult = NonNullable<Awaited<ReturnType<typeof getApiBillingCodeGetAliasesForUser>>>
export type PutApiBillingCodeToggleFavoriteResult = NonNullable<Awaited<ReturnType<typeof putApiBillingCodeToggleFavorite>>>
export type PostApiBillingCodeCreateAliasResult = NonNullable<Awaited<ReturnType<typeof postApiBillingCodeCreateAlias>>>
export type DeleteApiBillingCodeDeleteAliasResult = NonNullable<Awaited<ReturnType<typeof deleteApiBillingCodeDeleteAlias>>>
export type GetApiBlockedTimeSlotGetBlockedTimeSlotResult = NonNullable<Awaited<ReturnType<typeof getApiBlockedTimeSlotGetBlockedTimeSlot>>>
export type PostApiBlockedTimeSlotAddOrUpdateResult = NonNullable<Awaited<ReturnType<typeof postApiBlockedTimeSlotAddOrUpdate>>>
export type DeleteApiBlockedTimeSlotDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteApiBlockedTimeSlotDelete>>>
export type PostApiBlockedTimeSlotDeletePartiallyResult = NonNullable<Awaited<ReturnType<typeof postApiBlockedTimeSlotDeletePartially>>>
export type PostApiCHCheckEligibilityResult = NonNullable<Awaited<ReturnType<typeof postApiCHCheckEligibility>>>
export type GetApiCHSearchInsuranceResult = NonNullable<Awaited<ReturnType<typeof getApiCHSearchInsurance>>>
export type GetApiCHSearchInsuranceByOrgResult = NonNullable<Awaited<ReturnType<typeof getApiCHSearchInsuranceByOrg>>>
export type GetApiCHGetFavoriteInsurancesResult = NonNullable<Awaited<ReturnType<typeof getApiCHGetFavoriteInsurances>>>
export type GetApiCHGetFavoriteInsurancesByOrgResult = NonNullable<Awaited<ReturnType<typeof getApiCHGetFavoriteInsurancesByOrg>>>
export type PostApiCHClaimAcknowledgementHandlerResult = NonNullable<Awaited<ReturnType<typeof postApiCHClaimAcknowledgementHandler>>>
export type PostApiCHElectronicRemittanceAdviceHandlerResult = NonNullable<Awaited<ReturnType<typeof postApiCHElectronicRemittanceAdviceHandler>>>
export type PostApiCHEraPDFHandlerResult = NonNullable<Awaited<ReturnType<typeof postApiCHEraPDFHandler>>>
export type GetApiCHTestProcessStediERAResult = NonNullable<Awaited<ReturnType<typeof getApiCHTestProcessStediERA>>>
export type GetApiChargeSearchChargeMasterResult = NonNullable<Awaited<ReturnType<typeof getApiChargeSearchChargeMaster>>>
export type GetApiChargeSearchCustomChargeMasterResult = NonNullable<Awaited<ReturnType<typeof getApiChargeSearchCustomChargeMaster>>>
export type PostApiChargeCreateChargePolicyResult = NonNullable<Awaited<ReturnType<typeof postApiChargeCreateChargePolicy>>>
export type PostApiChargeCreateCustomCodeChargePolicyResult = NonNullable<Awaited<ReturnType<typeof postApiChargeCreateCustomCodeChargePolicy>>>
export type PostApiChargeModifyChargePolicyResult = NonNullable<Awaited<ReturnType<typeof postApiChargeModifyChargePolicy>>>
export type PostApiChargeModifyCustomCodeChargePolicyResult = NonNullable<Awaited<ReturnType<typeof postApiChargeModifyCustomCodeChargePolicy>>>
export type DeleteApiChargeDeleteChargePolicyResult = NonNullable<Awaited<ReturnType<typeof deleteApiChargeDeleteChargePolicy>>>
export type DeleteApiChargeDeleteCustomCodeChargePolicyResult = NonNullable<Awaited<ReturnType<typeof deleteApiChargeDeleteCustomCodeChargePolicy>>>
export type PostApiChargeGetChargesResult = NonNullable<Awaited<ReturnType<typeof postApiChargeGetCharges>>>
export type GetApiClaimSearchExternalProviderResult = NonNullable<Awaited<ReturnType<typeof getApiClaimSearchExternalProvider>>>
export type GetApiClaimSearchAdjustmentReasonCodeResult = NonNullable<Awaited<ReturnType<typeof getApiClaimSearchAdjustmentReasonCode>>>
export type GetApiClaimSearchNdcCodesResult = NonNullable<Awaited<ReturnType<typeof getApiClaimSearchNdcCodes>>>
export type PostApiClaimCreateClaimEncounterIdResult = NonNullable<Awaited<ReturnType<typeof postApiClaimCreateClaimEncounterId>>>
export type GetApiClaimGetClaimByIdClaimIdResult = NonNullable<Awaited<ReturnType<typeof getApiClaimGetClaimByIdClaimId>>>
export type DeleteApiClaimDeleteClaimByIdClaimIdResult = NonNullable<Awaited<ReturnType<typeof deleteApiClaimDeleteClaimByIdClaimId>>>
export type PostApiClaimSaveClaimResult = NonNullable<Awaited<ReturnType<typeof postApiClaimSaveClaim>>>
export type PostApiClaimTransmitClaimToClearinghouseClaimIdResult = NonNullable<Awaited<ReturnType<typeof postApiClaimTransmitClaimToClearinghouseClaimId>>>
export type PostApiClaimTransmitClaimsToClearinghouseByTagResult = NonNullable<Awaited<ReturnType<typeof postApiClaimTransmitClaimsToClearinghouseByTag>>>
export type PostApiClaimMarkClaimStatusResult = NonNullable<Awaited<ReturnType<typeof postApiClaimMarkClaimStatus>>>
export type PostApiClaimPrintClaimResult = NonNullable<Awaited<ReturnType<typeof postApiClaimPrintClaim>>>
export type GetApiClaimGetSplitByEncounterIdEncounterIdResult = NonNullable<Awaited<ReturnType<typeof getApiClaimGetSplitByEncounterIdEncounterId>>>
export type PostApiClaimSaveSplitResult = NonNullable<Awaited<ReturnType<typeof postApiClaimSaveSplit>>>
export type PostApiClaimClaimRequestResponseStatusClaimIdResult = NonNullable<Awaited<ReturnType<typeof postApiClaimClaimRequestResponseStatusClaimId>>>
export type PostApiClaimCreateSecondaryClaimResult = NonNullable<Awaited<ReturnType<typeof postApiClaimCreateSecondaryClaim>>>
export type PostApiCommunicationSendPatientInfoMessageResult = NonNullable<Awaited<ReturnType<typeof postApiCommunicationSendPatientInfoMessage>>>
export type GetApiCommunicationGetPatientInfoCommuniqueByTokenTokenResult = NonNullable<Awaited<ReturnType<typeof getApiCommunicationGetPatientInfoCommuniqueByTokenToken>>>
export type PostApiCommunicationSendReminderCommuniqueResult = NonNullable<Awaited<ReturnType<typeof postApiCommunicationSendReminderCommunique>>>
export type GetApiCommunicationGetAppointmentCommuniqueByTokenResult = NonNullable<Awaited<ReturnType<typeof getApiCommunicationGetAppointmentCommuniqueByToken>>>
export type PostApiCommunicationRequestAppointmentCommuniqueByPatientInfoResult = NonNullable<Awaited<ReturnType<typeof postApiCommunicationRequestAppointmentCommuniqueByPatientInfo>>>
export type GetApiCommunicationGetConsentFormPdfPatientIdAnvilTemplateIdResult = NonNullable<Awaited<ReturnType<typeof getApiCommunicationGetConsentFormPdfPatientIdAnvilTemplateId>>>
export type GetApiCommunicationGetProceduralConsentFormsInfoByPatientIdPatientIdResult = NonNullable<Awaited<ReturnType<typeof getApiCommunicationGetProceduralConsentFormsInfoByPatientIdPatientId>>>
export type GetApiCommunicationGetConsentFormByIdConsentFormIdResult = NonNullable<Awaited<ReturnType<typeof getApiCommunicationGetConsentFormByIdConsentFormId>>>
export type PostApiCommunicationSendConsentFormsResult = NonNullable<Awaited<ReturnType<typeof postApiCommunicationSendConsentForms>>>
export type GetApiCommunicationGetConsentFormCommuniqueByTokenTokenResult = NonNullable<Awaited<ReturnType<typeof getApiCommunicationGetConsentFormCommuniqueByTokenToken>>>
export type GetApiCommunicationGenerateSignURLForAppointmentReminderTokenResult = NonNullable<Awaited<ReturnType<typeof getApiCommunicationGenerateSignURLForAppointmentReminderToken>>>
export type GetApiCommunicationGenerateSignURLForConsentFormsTokenResult = NonNullable<Awaited<ReturnType<typeof getApiCommunicationGenerateSignURLForConsentFormsToken>>>
export type GetApiCustomServiceCodeSearchResult = NonNullable<Awaited<ReturnType<typeof getApiCustomServiceCodeSearch>>>
export type PostApiCustomServiceCodeToggleFavoriteResult = NonNullable<Awaited<ReturnType<typeof postApiCustomServiceCodeToggleFavorite>>>
export type PostApiEFaxSendFaxResult = NonNullable<Awaited<ReturnType<typeof postApiEFaxSendFax>>>
export type PostApiEFaxResendFaxResult = NonNullable<Awaited<ReturnType<typeof postApiEFaxResendFax>>>
export type PostApiEFaxGetFaxesResult = NonNullable<Awaited<ReturnType<typeof postApiEFaxGetFaxes>>>
export type PostApiEFaxSetInboundFaxInternalStatusResult = NonNullable<Awaited<ReturnType<typeof postApiEFaxSetInboundFaxInternalStatus>>>
export type PostApiEFaxUploadInboundFileToPatientChartResult = NonNullable<Awaited<ReturnType<typeof postApiEFaxUploadInboundFileToPatientChart>>>
export type PostApiEFaxAddFaxContactResult = NonNullable<Awaited<ReturnType<typeof postApiEFaxAddFaxContact>>>
export type GetApiEFaxGetFaxContactsResult = NonNullable<Awaited<ReturnType<typeof getApiEFaxGetFaxContacts>>>
export type PostApiEFaxUpdateFaxContactResult = NonNullable<Awaited<ReturnType<typeof postApiEFaxUpdateFaxContact>>>
export type DeleteApiEFaxDeleteFaxContactResult = NonNullable<Awaited<ReturnType<typeof deleteApiEFaxDeleteFaxContact>>>
export type GetApiEFaxDownloadFaxResult = NonNullable<Awaited<ReturnType<typeof getApiEFaxDownloadFax>>>
export type PostApiEFaxOutboundFaxEventHandlerResult = NonNullable<Awaited<ReturnType<typeof postApiEFaxOutboundFaxEventHandler>>>
export type PostApiEFaxInboundFaxEventHandlerResult = NonNullable<Awaited<ReturnType<typeof postApiEFaxInboundFaxEventHandler>>>
export type GetApiEncounterGetEncounterSummariesByDateResult = NonNullable<Awaited<ReturnType<typeof getApiEncounterGetEncounterSummariesByDate>>>
export type GetApiEncounterGetEncounterDetailsByIdResult = NonNullable<Awaited<ReturnType<typeof getApiEncounterGetEncounterDetailsById>>>
export type GetApiEncounterGetAppointmentsByDateResult = NonNullable<Awaited<ReturnType<typeof getApiEncounterGetAppointmentsByDate>>>
export type GetApiEncounterGetCalendarEventsByDateResult = NonNullable<Awaited<ReturnType<typeof getApiEncounterGetCalendarEventsByDate>>>
export type GetApiEncounterGetAppointmentsByDateRangeResult = NonNullable<Awaited<ReturnType<typeof getApiEncounterGetAppointmentsByDateRange>>>
export type GetApiEncounterGetCalendarEventsByDateRangeResult = NonNullable<Awaited<ReturnType<typeof getApiEncounterGetCalendarEventsByDateRange>>>
export type GetApiEncounterGetAppointmentDetailsByAppointmentIdAppointmentIdResult = NonNullable<Awaited<ReturnType<typeof getApiEncounterGetAppointmentDetailsByAppointmentIdAppointmentId>>>
export type PostApiEncounterCreateAppointmentResult = NonNullable<Awaited<ReturnType<typeof postApiEncounterCreateAppointment>>>
export type DeleteApiEncounterDeleteAppointmentResult = NonNullable<Awaited<ReturnType<typeof deleteApiEncounterDeleteAppointment>>>
export type GetApiEncounterGetDailyAppointmentBreakdownByDateRangeResult = NonNullable<Awaited<ReturnType<typeof getApiEncounterGetDailyAppointmentBreakdownByDateRange>>>
export type GetApiEncounterGetReferringProviderFromLastAppointmentResult = NonNullable<Awaited<ReturnType<typeof getApiEncounterGetReferringProviderFromLastAppointment>>>
export type PostApiEncounterSetAppointmentConfirmationResult = NonNullable<Awaited<ReturnType<typeof postApiEncounterSetAppointmentConfirmation>>>
export type PostApiEncounterSetAppointmentConfirmationViaVoiceAgentResult = NonNullable<Awaited<ReturnType<typeof postApiEncounterSetAppointmentConfirmationViaVoiceAgent>>>
export type PatchApiEncounterModifyAppointmentDetailsResult = NonNullable<Awaited<ReturnType<typeof patchApiEncounterModifyAppointmentDetails>>>
export type PostApiEncounterModifyAppointmentStatusResult = NonNullable<Awaited<ReturnType<typeof postApiEncounterModifyAppointmentStatus>>>
export type PostApiEncounterModifyAppointmentPaymentTypeResult = NonNullable<Awaited<ReturnType<typeof postApiEncounterModifyAppointmentPaymentType>>>
export type PostApiEncounterPrintEncounterScheduleResult = NonNullable<Awaited<ReturnType<typeof postApiEncounterPrintEncounterSchedule>>>
export type PostApiEncounterGetWriteoffsByEncounterIdResult = NonNullable<Awaited<ReturnType<typeof postApiEncounterGetWriteoffsByEncounterId>>>
export type GetApiERxUserNotificationCountsResult = NonNullable<Awaited<ReturnType<typeof getApiERxUserNotificationCounts>>>
export type GetApiERxUserWebpageURLResult = NonNullable<Awaited<ReturnType<typeof getApiERxUserWebpageURL>>>
export type GetApiERxPatientWebpageURLResult = NonNullable<Awaited<ReturnType<typeof getApiERxPatientWebpageURL>>>
export type GetApiERxMedicationSearchResult = NonNullable<Awaited<ReturnType<typeof getApiERxMedicationSearch>>>
export type GetApiERxDrugAllergySearchResult = NonNullable<Awaited<ReturnType<typeof getApiERxDrugAllergySearch>>>
export type GetApiERxPharmacySearchResult = NonNullable<Awaited<ReturnType<typeof getApiERxPharmacySearch>>>
export type PostApiMultimediaDeleteMultiMediaResult = NonNullable<Awaited<ReturnType<typeof postApiMultimediaDeleteMultiMedia>>>
export type PostApiMultimediaRequestMultiMediaResult = NonNullable<Awaited<ReturnType<typeof postApiMultimediaRequestMultiMedia>>>
export type PostApiMultimediaPostPatientMultiMediaResult = NonNullable<Awaited<ReturnType<typeof postApiMultimediaPostPatientMultiMedia>>>
export type PutApiMultimediaUpdatePatientMultiMediaResult = NonNullable<Awaited<ReturnType<typeof putApiMultimediaUpdatePatientMultiMedia>>>
export type PostApiMultimediaPostBillingMultiMediaResult = NonNullable<Awaited<ReturnType<typeof postApiMultimediaPostBillingMultiMedia>>>
export type PostApiMultimediaPostOrgMultiMediaResult = NonNullable<Awaited<ReturnType<typeof postApiMultimediaPostOrgMultiMedia>>>
export type GetApiMultimediaGetOrgMultiMediaByCategoryOrgIdMediaCategoryResult = NonNullable<Awaited<ReturnType<typeof getApiMultimediaGetOrgMultiMediaByCategoryOrgIdMediaCategory>>>
export type GetApiMultimediaGetBillingMultiMediaByIdBillingMultiMediaIdMediaCategoryResult = NonNullable<Awaited<ReturnType<typeof getApiMultimediaGetBillingMultiMediaByIdBillingMultiMediaIdMediaCategory>>>
export type GetApiMultimediaGetPatientMultiMediaByIdPatientMultiMediaIdResult = NonNullable<Awaited<ReturnType<typeof getApiMultimediaGetPatientMultiMediaByIdPatientMultiMediaId>>>
export type PostApiMultimediaGetPatientMultiMediaResult = NonNullable<Awaited<ReturnType<typeof postApiMultimediaGetPatientMultiMedia>>>
export type GetApiMultimediaGetPatientMultiMediaByCategoryPatientIdMediaCategoryResult = NonNullable<Awaited<ReturnType<typeof getApiMultimediaGetPatientMultiMediaByCategoryPatientIdMediaCategory>>>
export type GetApiMultimediaGetFaxMultimediaByFaxIdResult = NonNullable<Awaited<ReturnType<typeof getApiMultimediaGetFaxMultimediaByFaxId>>>
export type GetApiOrganizationGetOrgByPatientIdResult = NonNullable<Awaited<ReturnType<typeof getApiOrganizationGetOrgByPatientId>>>
export type PostApiOrganizationGetOrgByIpadBaseIntakeUrlResult = NonNullable<Awaited<ReturnType<typeof postApiOrganizationGetOrgByIpadBaseIntakeUrl>>>
export type PostApiOrganizationAddConsentFormResult = NonNullable<Awaited<ReturnType<typeof postApiOrganizationAddConsentForm>>>
export type PostApiOrganizationUpdateConsentFormResult = NonNullable<Awaited<ReturnType<typeof postApiOrganizationUpdateConsentForm>>>
export type PostApiOrganizationGetConsentFormEditorResult = NonNullable<Awaited<ReturnType<typeof postApiOrganizationGetConsentFormEditor>>>
export type PostApiOrganizationGetAllConsentFormsByOrgResult = NonNullable<Awaited<ReturnType<typeof postApiOrganizationGetAllConsentFormsByOrg>>>
export type DeleteApiOrganizationDeleteConsentFormResult = NonNullable<Awaited<ReturnType<typeof deleteApiOrganizationDeleteConsentForm>>>
export type PostApiPatientCreateNewPatientResult = NonNullable<Awaited<ReturnType<typeof postApiPatientCreateNewPatient>>>
export type GetApiPatientSearchPatientsResult = NonNullable<Awaited<ReturnType<typeof getApiPatientSearchPatients>>>
export type GetApiPatientGetPatientByIdIdResult = NonNullable<Awaited<ReturnType<typeof getApiPatientGetPatientByIdId>>>
export type GetApiPatientGetPatientByIdWithPharmacyMedicationAndAllergyIdResult = NonNullable<Awaited<ReturnType<typeof getApiPatientGetPatientByIdWithPharmacyMedicationAndAllergyId>>>
export type GetApiPatientGetCountsSummaryPatientIdResult = NonNullable<Awaited<ReturnType<typeof getApiPatientGetCountsSummaryPatientId>>>
export type GetApiPatientGetPatientMedicalBackgroundIdResult = NonNullable<Awaited<ReturnType<typeof getApiPatientGetPatientMedicalBackgroundId>>>
export type GetApiPatientGetPatientInsurancesByIdIdResult = NonNullable<Awaited<ReturnType<typeof getApiPatientGetPatientInsurancesByIdId>>>
export type PostApiPatientAddPatientMedicalHistoryResult = NonNullable<Awaited<ReturnType<typeof postApiPatientAddPatientMedicalHistory>>>
export type PostApiPatientAddPatientPharmacyAllergyAndMedicationResult = NonNullable<Awaited<ReturnType<typeof postApiPatientAddPatientPharmacyAllergyAndMedication>>>
export type PatchApiPatientUpdatePatientResult = NonNullable<Awaited<ReturnType<typeof patchApiPatientUpdatePatient>>>
export type PostApiPatientAddUpdateOrDeletePatientInsurancesResult = NonNullable<Awaited<ReturnType<typeof postApiPatientAddUpdateOrDeletePatientInsurances>>>
export type PostApiPatientAddPatientInsuranceResult = NonNullable<Awaited<ReturnType<typeof postApiPatientAddPatientInsurance>>>
export type PostApiPatientAddReasonForVisitResult = NonNullable<Awaited<ReturnType<typeof postApiPatientAddReasonForVisit>>>
export type GetApiPatientGetSymptomsFromImmediatePastVisitPatientIdResult = NonNullable<Awaited<ReturnType<typeof getApiPatientGetSymptomsFromImmediatePastVisitPatientId>>>
export type GetApiPatientGetConsentFormByPatientIdPatientIdResult = NonNullable<Awaited<ReturnType<typeof getApiPatientGetConsentFormByPatientIdPatientId>>>
export type PostApiPatientSubmitGeneralConsentFormResult = NonNullable<Awaited<ReturnType<typeof postApiPatientSubmitGeneralConsentForm>>>
export type PostApiPatientSubmitDosespotConsentFormResult = NonNullable<Awaited<ReturnType<typeof postApiPatientSubmitDosespotConsentForm>>>
export type PostApiPatientSubmitProceduralConsentFormResult = NonNullable<Awaited<ReturnType<typeof postApiPatientSubmitProceduralConsentForm>>>
export type PostApiPatientVerifyPatientDOBByIdResult = NonNullable<Awaited<ReturnType<typeof postApiPatientVerifyPatientDOBById>>>
export type GetApiPatientGetPatientLedgerPatientIdResult = NonNullable<Awaited<ReturnType<typeof getApiPatientGetPatientLedgerPatientId>>>
export type GetApiPatientPrintPatientFaceSheetResult = NonNullable<Awaited<ReturnType<typeof getApiPatientPrintPatientFaceSheet>>>
export type GetApiProviderAvailabilityGetProviderAvailabilityResult = NonNullable<Awaited<ReturnType<typeof getApiProviderAvailabilityGetProviderAvailability>>>
export type PostApiProviderAvailabilityAddOrUpdateResult = NonNullable<Awaited<ReturnType<typeof postApiProviderAvailabilityAddOrUpdate>>>
export type DeleteApiProviderAvailabilityDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteApiProviderAvailabilityDelete>>>
export type PostApiProviderAvailabilityDeletePartiallyResult = NonNullable<Awaited<ReturnType<typeof postApiProviderAvailabilityDeletePartially>>>
export type GetApiReportGetReportSettingsResult = NonNullable<Awaited<ReturnType<typeof getApiReportGetReportSettings>>>
export type PostApiReportTriggerReportResult = NonNullable<Awaited<ReturnType<typeof postApiReportTriggerReport>>>
export type PostApiReportGetReportResultsResult = NonNullable<Awaited<ReturnType<typeof postApiReportGetReportResults>>>
export type GetApiReportDownloadReportResult = NonNullable<Awaited<ReturnType<typeof getApiReportDownloadReport>>>
export type PostApiReportDeleteReportResult = NonNullable<Awaited<ReturnType<typeof postApiReportDeleteReport>>>
export type PostApiReportHandleReportCompleteResult = NonNullable<Awaited<ReturnType<typeof postApiReportHandleReportComplete>>>
export type PostApiScheduledJobRunBatchSyncMedicalHistoryForOrgOrgIdResult = NonNullable<Awaited<ReturnType<typeof postApiScheduledJobRunBatchSyncMedicalHistoryForOrgOrgId>>>
export type PostApiScheduledJobRunOldPatientDosespotSyncResult = NonNullable<Awaited<ReturnType<typeof postApiScheduledJobRunOldPatientDosespotSync>>>
export type GetApiSettingsGetIntakeFormSettingsResult = NonNullable<Awaited<ReturnType<typeof getApiSettingsGetIntakeFormSettings>>>
export type GetApiSettingsGetClaimSettingsResult = NonNullable<Awaited<ReturnType<typeof getApiSettingsGetClaimSettings>>>
export type GetApiSettingsGetChargeFilterSettingsResult = NonNullable<Awaited<ReturnType<typeof getApiSettingsGetChargeFilterSettings>>>
export type GetApiSettingsGetPatientPaymentFilterSettingsResult = NonNullable<Awaited<ReturnType<typeof getApiSettingsGetPatientPaymentFilterSettings>>>
export type GetApiSettingsGetPatientMultimediaFilterSettingsResult = NonNullable<Awaited<ReturnType<typeof getApiSettingsGetPatientMultimediaFilterSettings>>>
export type GetApiSettingsGetAppointmentSettingsResult = NonNullable<Awaited<ReturnType<typeof getApiSettingsGetAppointmentSettings>>>
export type GetApiSettingsGetAppointmentTypeSettingsResult = NonNullable<Awaited<ReturnType<typeof getApiSettingsGetAppointmentTypeSettings>>>
export type PostApiSettingsAddAppointmentTypeSettingResult = NonNullable<Awaited<ReturnType<typeof postApiSettingsAddAppointmentTypeSetting>>>
export type PostApiSettingsUpdateAppointmentTypeSettingResult = NonNullable<Awaited<ReturnType<typeof postApiSettingsUpdateAppointmentTypeSetting>>>
export type GetApiSettingsGetToDoSettingsResult = NonNullable<Awaited<ReturnType<typeof getApiSettingsGetToDoSettings>>>
export type GetApiSettingsGetWriteOffSettingsResult = NonNullable<Awaited<ReturnType<typeof getApiSettingsGetWriteOffSettings>>>
export type GetApiSettingsGetEFaxFilterSettingsResult = NonNullable<Awaited<ReturnType<typeof getApiSettingsGetEFaxFilterSettings>>>
export type GetApiSettingsGetUserManagementSettingsResult = NonNullable<Awaited<ReturnType<typeof getApiSettingsGetUserManagementSettings>>>
export type GetApiSettingsGetOrCreateUserSettingsResult = NonNullable<Awaited<ReturnType<typeof getApiSettingsGetOrCreateUserSettings>>>
export type PostApiSettingsCreateOrUpdateUserSettingsResult = NonNullable<Awaited<ReturnType<typeof postApiSettingsCreateOrUpdateUserSettings>>>
export type GetApiSettingsGetPayerEntryConfigsByTypeResult = NonNullable<Awaited<ReturnType<typeof getApiSettingsGetPayerEntryConfigsByType>>>
export type PostApiSettingsAddPaperPayerEntryResult = NonNullable<Awaited<ReturnType<typeof postApiSettingsAddPaperPayerEntry>>>
export type PutApiSettingsUpdatePaperPayerEntryResult = NonNullable<Awaited<ReturnType<typeof putApiSettingsUpdatePaperPayerEntry>>>
export type DeleteApiSettingsDeletePaperPayerEntryResult = NonNullable<Awaited<ReturnType<typeof deleteApiSettingsDeletePaperPayerEntry>>>
export type PostApiSettingsAddElectronicPayerEntryConfigResult = NonNullable<Awaited<ReturnType<typeof postApiSettingsAddElectronicPayerEntryConfig>>>
export type PutApiSettingsUpdateElectronicPayerEntryConfigResult = NonNullable<Awaited<ReturnType<typeof putApiSettingsUpdateElectronicPayerEntryConfig>>>
export type DeleteApiSettingsDeleteElectronicPayerEntryConfigResult = NonNullable<Awaited<ReturnType<typeof deleteApiSettingsDeleteElectronicPayerEntryConfig>>>
export type GetApiSettingsMigratePaperPayersResult = NonNullable<Awaited<ReturnType<typeof getApiSettingsMigratePaperPayers>>>
export type GetApiSquareAuthorizationGetAuthorizationUrlResult = NonNullable<Awaited<ReturnType<typeof getApiSquareAuthorizationGetAuthorizationUrl>>>
export type GetApiSquareAuthorizationAuthorizationRedirectResult = NonNullable<Awaited<ReturnType<typeof getApiSquareAuthorizationAuthorizationRedirect>>>
export type PostApiSquareAuthorizationRetrieveTokenStatusResult = NonNullable<Awaited<ReturnType<typeof postApiSquareAuthorizationRetrieveTokenStatus>>>
export type PostApiSquareAuthorizationRefreshTokenResult = NonNullable<Awaited<ReturnType<typeof postApiSquareAuthorizationRefreshToken>>>
export type PostApiSquareAuthorizationRevokeTokenResult = NonNullable<Awaited<ReturnType<typeof postApiSquareAuthorizationRevokeToken>>>
export type PostApiSquarePaymentsHandleEventNotificationResult = NonNullable<Awaited<ReturnType<typeof postApiSquarePaymentsHandleEventNotification>>>
export type GetApiSquarePaymentsListLocationsResult = NonNullable<Awaited<ReturnType<typeof getApiSquarePaymentsListLocations>>>
export type GetApiSquarePaymentsGetDeviceByDeviceIdResult = NonNullable<Awaited<ReturnType<typeof getApiSquarePaymentsGetDeviceByDeviceId>>>
export type GetApiSquarePaymentsListDevicesResult = NonNullable<Awaited<ReturnType<typeof getApiSquarePaymentsListDevices>>>
export type PostApiSquarePaymentsCreateDeviceCodeForLocationResult = NonNullable<Awaited<ReturnType<typeof postApiSquarePaymentsCreateDeviceCodeForLocation>>>
export type PostApiSquarePaymentsCreateTerminalCheckoutResult = NonNullable<Awaited<ReturnType<typeof postApiSquarePaymentsCreateTerminalCheckout>>>
export type PostApiSquarePaymentsGetPaymentResult = NonNullable<Awaited<ReturnType<typeof postApiSquarePaymentsGetPayment>>>
export type PostApiSquarePaymentsCancelTerminalCheckoutResult = NonNullable<Awaited<ReturnType<typeof postApiSquarePaymentsCancelTerminalCheckout>>>
export type PostApiSquarePaymentsCreateQuickPayCheckoutResult = NonNullable<Awaited<ReturnType<typeof postApiSquarePaymentsCreateQuickPayCheckout>>>
export type GetApiStickyGetAllStickiesByPatientIdResult = NonNullable<Awaited<ReturnType<typeof getApiStickyGetAllStickiesByPatientId>>>
export type GetApiStickyGetStickyByIdResult = NonNullable<Awaited<ReturnType<typeof getApiStickyGetStickyById>>>
export type PostApiStickyCreateStickyResult = NonNullable<Awaited<ReturnType<typeof postApiStickyCreateSticky>>>
export type PostApiStickyUpdateStickyResult = NonNullable<Awaited<ReturnType<typeof postApiStickyUpdateSticky>>>
export type DeleteApiStickyDeleteStickyResult = NonNullable<Awaited<ReturnType<typeof deleteApiStickyDeleteSticky>>>
export type PostApiTemplateSaveSectionTemplateResult = NonNullable<Awaited<ReturnType<typeof postApiTemplateSaveSectionTemplate>>>
export type GetApiTemplateRetrieveSectionTemplateResult = NonNullable<Awaited<ReturnType<typeof getApiTemplateRetrieveSectionTemplate>>>
export type DeleteApiTemplateDeleteSectionTemplateResult = NonNullable<Awaited<ReturnType<typeof deleteApiTemplateDeleteSectionTemplate>>>
export type GetApiTemplateGetSectionTemplateListResult = NonNullable<Awaited<ReturnType<typeof getApiTemplateGetSectionTemplateList>>>
export type GetApiTemplateRetrieveSectionFromPriorVisitNotePatientIdSectionNameCurrentVisitNoteIdResult = NonNullable<Awaited<ReturnType<typeof getApiTemplateRetrieveSectionFromPriorVisitNotePatientIdSectionNameCurrentVisitNoteId>>>
export type PostApiTemplateSaveMasterTemplateResult = NonNullable<Awaited<ReturnType<typeof postApiTemplateSaveMasterTemplate>>>
export type GetApiTemplateRetrieveMasterTemplateResult = NonNullable<Awaited<ReturnType<typeof getApiTemplateRetrieveMasterTemplate>>>
export type DeleteApiTemplateDeleteMasterTemplateResult = NonNullable<Awaited<ReturnType<typeof deleteApiTemplateDeleteMasterTemplate>>>
export type GetApiTemplateGetMasterTemplateListResult = NonNullable<Awaited<ReturnType<typeof getApiTemplateGetMasterTemplateList>>>
export type GetApiTemplateGetFieldListResult = NonNullable<Awaited<ReturnType<typeof getApiTemplateGetFieldList>>>
export type GetApiTemplateGetFieldFieldIdResult = NonNullable<Awaited<ReturnType<typeof getApiTemplateGetFieldFieldId>>>
export type PostApiTemplateAddFieldResult = NonNullable<Awaited<ReturnType<typeof postApiTemplateAddField>>>
export type PutApiTemplateEditFieldResult = NonNullable<Awaited<ReturnType<typeof putApiTemplateEditField>>>
export type DeleteApiTemplateDeleteFieldResult = NonNullable<Awaited<ReturnType<typeof deleteApiTemplateDeleteField>>>
export type PostApiToDoRequestToDoListResult = NonNullable<Awaited<ReturnType<typeof postApiToDoRequestToDoList>>>
export type GetApiToDoGetToDoByIdResult = NonNullable<Awaited<ReturnType<typeof getApiToDoGetToDoById>>>
export type GetApiToDoGetCommentsByToDoIdResult = NonNullable<Awaited<ReturnType<typeof getApiToDoGetCommentsByToDoId>>>
export type PostApiToDoCreateToDoResult = NonNullable<Awaited<ReturnType<typeof postApiToDoCreateToDo>>>
export type PostApiToDoCommentOnToDoResult = NonNullable<Awaited<ReturnType<typeof postApiToDoCommentOnToDo>>>
export type PutApiToDoUpdateToDoResult = NonNullable<Awaited<ReturnType<typeof putApiToDoUpdateToDo>>>
export type GetApiToDoGetUnreadToDoCountOfUserResult = NonNullable<Awaited<ReturnType<typeof getApiToDoGetUnreadToDoCountOfUser>>>
export type GetApiToDoGetFaxToDosResult = NonNullable<Awaited<ReturnType<typeof getApiToDoGetFaxToDos>>>
export type GetApiUserGetAllUsersByTitleTitleResult = NonNullable<Awaited<ReturnType<typeof getApiUserGetAllUsersByTitleTitle>>>
export type GetApiUserGetLoggedInUserInfoResult = NonNullable<Awaited<ReturnType<typeof getApiUserGetLoggedInUserInfo>>>
export type GetApiUserGetUsersResult = NonNullable<Awaited<ReturnType<typeof getApiUserGetUsers>>>
export type PostApiUserCreateUserResult = NonNullable<Awaited<ReturnType<typeof postApiUserCreateUser>>>
export type PostApiUserUpdateUserResult = NonNullable<Awaited<ReturnType<typeof postApiUserUpdateUser>>>
export type PostApiUserAuthenticateCurrentUserInNablaResult = NonNullable<Awaited<ReturnType<typeof postApiUserAuthenticateCurrentUserInNabla>>>
export type GetApiVisitNoteGetAllVisitNotesByPatientIdPatientIdResult = NonNullable<Awaited<ReturnType<typeof getApiVisitNoteGetAllVisitNotesByPatientIdPatientId>>>
export type GetApiVisitNoteGetUpcomingAppointmentsByPatientIdPatientIdResult = NonNullable<Awaited<ReturnType<typeof getApiVisitNoteGetUpcomingAppointmentsByPatientIdPatientId>>>
export type GetApiVisitNoteGetHistoricalVisitsByPatientIdPatientIdResult = NonNullable<Awaited<ReturnType<typeof getApiVisitNoteGetHistoricalVisitsByPatientIdPatientId>>>
export type GetApiVisitNoteGetVisitNoteByEncounterIdEncounterIdResult = NonNullable<Awaited<ReturnType<typeof getApiVisitNoteGetVisitNoteByEncounterIdEncounterId>>>
export type GetApiVisitNoteGetAppointmentRelatedInfoByVisitNoteIdVisitNoteIdResult = NonNullable<Awaited<ReturnType<typeof getApiVisitNoteGetAppointmentRelatedInfoByVisitNoteIdVisitNoteId>>>
export type GetApiVisitNoteGetUserFavoriteMedicalCodesResult = NonNullable<Awaited<ReturnType<typeof getApiVisitNoteGetUserFavoriteMedicalCodes>>>
export type PostApiVisitNoteSaveVisitNoteResult = NonNullable<Awaited<ReturnType<typeof postApiVisitNoteSaveVisitNote>>>
export type GetApiVisitNoteRetrieveBillingAssessmentCodesFromPriorVisitNotePatientIdCurrentVisitNoteIdResult = NonNullable<Awaited<ReturnType<typeof getApiVisitNoteRetrieveBillingAssessmentCodesFromPriorVisitNotePatientIdCurrentVisitNoteId>>>
export type GetApiVisitNoteRetrieveBillingCodesFromPriorVisitNotePatientIdCurrentVisitNoteIdResult = NonNullable<Awaited<ReturnType<typeof getApiVisitNoteRetrieveBillingCodesFromPriorVisitNotePatientIdCurrentVisitNoteId>>>
export type GetApiVisitNoteRetrieveCustomServiceCodesFromPriorVisitNotePatientIdCurrentVisitNoteIdResult = NonNullable<Awaited<ReturnType<typeof getApiVisitNoteRetrieveCustomServiceCodesFromPriorVisitNotePatientIdCurrentVisitNoteId>>>
export type GetApiVisitNoteGetHPIInParagraphFormResult = NonNullable<Awaited<ReturnType<typeof getApiVisitNoteGetHPIInParagraphForm>>>
export type GetApiVisitNoteGetAICodeSuggestionsEncounterIdCodeTypeResult = NonNullable<Awaited<ReturnType<typeof getApiVisitNoteGetAICodeSuggestionsEncounterIdCodeType>>>
export type PutApiVisitNoteAddAddendumResult = NonNullable<Awaited<ReturnType<typeof putApiVisitNoteAddAddendum>>>
export type GetApiVisitNoteGetAddendumFinalizedVisitNoteIdResult = NonNullable<Awaited<ReturnType<typeof getApiVisitNoteGetAddendumFinalizedVisitNoteId>>>
export type GetApiVisitNoteRetrieveLastVisitNoteResult = NonNullable<Awaited<ReturnType<typeof getApiVisitNoteRetrieveLastVisitNote>>>
export type GetApiVisitNoteGetPastVisitNotesForPatientResult = NonNullable<Awaited<ReturnType<typeof getApiVisitNoteGetPastVisitNotesForPatient>>>
export type GetApiVisitNoteGetSpecificVisitNoteForPatientResult = NonNullable<Awaited<ReturnType<typeof getApiVisitNoteGetSpecificVisitNoteForPatient>>>
export type PostApiVisitNoteUnFinalizeResult = NonNullable<Awaited<ReturnType<typeof postApiVisitNoteUnFinalize>>>
export type PostApiVisitNotePrintVisitNotePdfEncounterIdResult = NonNullable<Awaited<ReturnType<typeof postApiVisitNotePrintVisitNotePdfEncounterId>>>
export type GetApiVisitNoteGetMipsSuggestionVisitNoteIdResult = NonNullable<Awaited<ReturnType<typeof getApiVisitNoteGetMipsSuggestionVisitNoteId>>>
export type GetApiVisitNoteGetMipsSubmissionDataResult = NonNullable<Awaited<ReturnType<typeof getApiVisitNoteGetMipsSubmissionData>>>
export type PostCallPatientForAppointmentConfirmationResult = NonNullable<Awaited<ReturnType<typeof postCallPatientForAppointmentConfirmation>>>
export type PostCallForAppointmentConfirmationResult = NonNullable<Awaited<ReturnType<typeof postCallForAppointmentConfirmation>>>
export type GetApiVoiceAssistantGetSessionResult = NonNullable<Awaited<ReturnType<typeof getApiVoiceAssistantGetSession>>>
export type GetApiVoiceAssistantInitializeEmbeddingsResult = NonNullable<Awaited<ReturnType<typeof getApiVoiceAssistantInitializeEmbeddings>>>
export type GetApiVoiceAssistantInitializeAppointmentEmbeddingsResult = NonNullable<Awaited<ReturnType<typeof getApiVoiceAssistantInitializeAppointmentEmbeddings>>>
export type GetApiVoiceAssistantInitializePatientEmbeddingsResult = NonNullable<Awaited<ReturnType<typeof getApiVoiceAssistantInitializePatientEmbeddings>>>
export type GetApiVoiceAssistantGetQueryAnswerResult = NonNullable<Awaited<ReturnType<typeof getApiVoiceAssistantGetQueryAnswer>>>
export type GetApiVoiceAssistantGetContextFromPopulateResult = NonNullable<Awaited<ReturnType<typeof getApiVoiceAssistantGetContextFromPopulate>>>
