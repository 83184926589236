import React, {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import AutoCompletedInput from 'core/components/AutoComplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import uniqueId from 'lodash/uniqueId';
import {
  AddressDTO,
} from 'dtos';
import isNil from 'lodash/isNil';
import {
  autosuggestHighlightParse,
} from 'utils/misc';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  useGoogleMapGeoCode,
  useGetGooglePlaces,
} from 'pages/Dashboard/pages/Charts/pages/Details/components/hooks';
import debounce from 'lodash/debounce';

export type GeoCodeAddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};

export type GeoCodeAddress = keyof Omit<GeoCodeAddressComponent, 'types'>;

export const geoCodeFieldsMapping = {
  street: ['street_number', 'route'],
  unit: ['subpremise'],
  sublocality: ['sublocality'],
  locality: ['locality'],
  neighborhood: ['neighborhood'],
  state: ['administrative_area_level_1'],
  country: ['country'],
  zipCode: ['postal_code'],
  postalCodeSuffix: ['postal_code_suffix'],
  longZipCode: ['postal_code', 'postal_code_suffix'],
} as const;

export type GeoCodeAddressCategory = keyof typeof geoCodeFieldsMapping;

export type Place = Partial<google.maps.places.AutocompletePrediction> | null;

type Props = {
  onChange?: (value: AddressDTO, event?: ChangeEvent<HTMLInputElement>) => void;
  value?: Place;
  onBlur?: () => void;
};

export default function AddressSuggestion({ onChange, value, onBlur }: Props) {
  const [address, setAddress] = useState<string | undefined>('');
  const [inputValue, setInputValue] = useState('');

  const { control, setValue } = useForm();
  const { data: options } = useGetGooglePlaces(inputValue);

  const { data, error: geoCodeError } = useGoogleMapGeoCode(address ?? '');

  useEffect(() => {
    if (isNil(geoCodeError) && !isNil(data)) {
      onChange?.(data);
    }
  }, [data]);

  useEffect(() => {
    if (!isNil(value)) {
      setValue('place', value);
    }
  }, [value]);

  const onInputChange = useCallback(debounce((
    event: SyntheticEvent,
    value: string,
  ) => {
    if (!isNil(event)) {
      setInputValue(value);
    }
  }, 700), []);

  return (
    <Controller
      name="place"
      control={control}
      render={({ field: { value } }) => (
        <AutoCompletedInput<Place>
          id="address-suggestion"
          className="w-full !pointer-events-auto"
          onBlur={onBlur}
          getLabel={(option) => (option?.description ?? '')}
          options={options ?? []}
          filterOptions={(x) => x}
          clearOnBlur={false}
          value={value ?? null}
          filterSelectedOptions
          noOptionsText="No locations"
          placeholder="Enter Address"
          clearIcon={<CloseIcon className="!h-4 !w-4 -m-0.5" />}
          onChange={(value) => {
            const newValue = value as Place;
            setValue('place', newValue);
            setAddress(newValue?.description);
          }}
          onInputChange={onInputChange}
          renderOption={(props, option) => {
            const matches = option?.structured_formatting?.main_text_matched_substrings ?? [];
            const parts = autosuggestHighlightParse(
              option?.structured_formatting?.main_text ?? '',
              matches.map((match) => [match.offset, match.offset + match.length]),
            );
            return (
              <li {...props} key={uniqueId('option_')} className="flex items-center hover:bg-gray-100 !text-xs !my-2 cursor-pointer">
                <div className="mx-1">
                  <LocationOnIcon className="text-gray-400 !text-base" />
                </div>
                <div className="w-[calc(100%-44px)]">
                  {parts.map((part, index) => (
                    <span
                      key={uniqueId(`part_${index}`)}
                      className={clsx({ 'font-semibold': part.highlight })}
                    >
                      {part.text}
                    </span>
                  ))}
                  <p className=" text-gray-400">
                    {option?.structured_formatting?.secondary_text}
                  </p>
                </div>
              </li>
            );
          }}
        />
      )}
    />
  );
}
