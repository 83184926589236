import React, {
  lazy,
} from 'react';
import {
  Navigate,
  RouteObject,
} from 'react-router-dom';
import LazyLoadComponent from 'core/components/LazyLoadComponent';

export const settingsRoutes: RouteObject[] = [{
  path: '/settings',
  element: <Navigate to="/settings/users" replace />,
}, {
  path: '/settings/users',
  element: (
    <LazyLoadComponent Component={lazy(() => import('./Users'))} />
  ),
}, {
  path: '/settings/appointments',
  element: (
    <LazyLoadComponent Component={lazy(() => import('./Appointments'))} />
  ),
}, {
  path: '/settings/visit-notes/:config',
  element: (
    <LazyLoadComponent Component={lazy(() => import('./VisitNotes'))} />
  ),
}, {
  path: '/settings/payers/:type',
  element: (
    <LazyLoadComponent Component={lazy(() => import('./Payers'))} />
  ),
}, {
  path: '/settings/visit-notes',
  element: <Navigate to="/settings/visit-notes/master-templates" replace />,
}, {
  path: '/settings/payers',
  element: <Navigate to="/settings/payers/electronic" replace />,
}, {
  path: '/settings/forms',
  element: (
    <LazyLoadComponent Component={lazy(() => import('./Forms'))} />
  ),
}];
