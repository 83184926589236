import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useForm,
} from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import pick from 'lodash/pick';
import values from 'lodash/values';
import isNil from 'lodash/isNil';
import {
  AddressDTO,
} from 'dtos';
import Controller from 'core/components/FormController';
import {
  ControllerRenderProps,
} from 'react-hook-form/dist/types/controller';
import {
  formatPlace,
  notEmptyString,
} from 'utils/misc';
import {
  validateZipCode,
  addressFields,
} from 'utils/react-hook-form';
import ZipCodeTextField from 'core/components/ZipCodeTextField';
import Input from 'pages/Dashboard/components/Input';
import Wrapper from 'pages/Dashboard/components/Input/Wrapper';
import USStateSelect from 'pages/Dashboard/components/USStateSelect';
import AddressSuggestion, {
  Place,
} from 'pages/Dashboard/components/AddressSuggestion';
import Accordion from 'core/components/Accordion';

export type AddressForm = Pick<AddressDTO,
  'street'
  | 'unit'
  | 'city'
  | 'state'
  | 'zipCode'
  | 'country'
> & {
  place?: Place;
};

type Props = {
  value?: AddressForm | null;
  onChange: (value: AddressDTO | null) => void;
  disabled?: boolean;
  onBlur?: () => void;
  required?: boolean;
  label?: string;
};

export default function AddressFields({
  value,
  onChange,
  disabled,
  onBlur,
  label = 'Address',
  required: _required = false,
}: Props) {
  const {
    control,
    watch,
    reset,
    trigger,
    formState: { isDirty, isValid },
  } = useForm<AddressForm>({ mode: 'onChange' });

  const formValue = watch();
  const [initialized, setInitialized] = useState<boolean>(false);

  const address = pick(value, addressFields);
  const required = useMemo<boolean>(
    () => _required || values(address).some(notEmptyString),
    [address, _required],
  );
  const isEmptyForm = useMemo(() => compact(values(formValue)).length === 0, [formValue]);

  useEffect(() => {
    if (isDirty) {
      reset({ ...formValue, ...formatPlace(formValue) }, { keepErrors: true });
      onChange(formValue);
    }

    if (initialized) {
      trigger();
    }
  }, [isDirty, value, initialized]);

  // settings default values
  // in case default values weren't attached
  useEffect(() => {
    if (isEmptyForm && !initialized && !isEmpty(value)) {
      reset({ ...value, ...formatPlace(value) }, { keepDirty: false });
      setInitialized(true);
    }
  }, [value, isEmptyForm, initialized]);

  const handleOnBlur = (field: Partial<ControllerRenderProps<any, any>>) => () => {
    field?.onBlur?.();
    onBlur?.();
  };

  const onChangeAddress = (address: AddressDTO) => {
    const updatedAddress = { ...address, ...formatPlace(address) };
    reset(updatedAddress);
    onChange(updatedAddress);
  };

  return (
    <div className="w-full">
      <Accordion
        defaultExpanded
        headerClasses={{
          root: '!p-0 !m-0 !bg-transparent !h-[52px]',
          content: '!m-0',
          expandIconWrapper: 'mt-4 !pointer-events-auto',
        }}
        className="!border-none !pointer-events-none"
        detailsClasses={{
          root: '!p-0 !pointer-events-auto',
        }}
        header={(
          <div
            onClick={(e) => { e.stopPropagation(); }}
            onKeyUp={() => {}}
            role="button"
            tabIndex={0}
            className="w-full"
          >
            <Wrapper
              id="address"
              label={label}
              className="w-full"
              error={initialized && !isValid}
              showErrorText={false}
            >
              <Controller
                name="place"
                control={control}
                render={({ field }) => (
                  <AddressSuggestion
                    {...field}
                    onBlur={handleOnBlur(field)}
                    onChange={onChangeAddress}
                  />
                )}
              />
            </Wrapper>
          </div>
        )}
      >
        <div className="flex gap-4 w-full mt-4">
          <Controller
            name="street"
            control={control}
            rules={{ required }}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                error={!isNil(error)}
                showErrorText={false}
                className="w-1/4"
                label="Street"
                placeholder="Street"
                disabled={disabled}
                required={required}
                id="street"
                onBlur={handleOnBlur(field)}
              />
            )}
          />
          <Controller
            name="unit"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onBlur={handleOnBlur(field)}
                className="w-1/6"
                label="Unit"
                placeholder="Unit"
                disabled={disabled}
                id="address-unit"
              />
            )}
          />
          <Controller
            name="city"
            control={control}
            rules={{ required }}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                error={!isNil(error)}
                showErrorText={false}
                className="w-1/4"
                label="City"
                placeholder="City"
                required={required}
                disabled={disabled}
                onBlur={handleOnBlur(field)}
                id="city"
              />
            )}
          />
          <Controller
            name="state"
            rules={{ required }}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Wrapper
                id="state"
                label="State"
                className="w-1/4"
                error={!isNil(error)}
                required={required}
                showErrorText={false}
              >
                <USStateSelect
                  className="w-full"
                  {...field}
                  onBlur={handleOnBlur(field)}
                />
              </Wrapper>
            )}
          />
          <Controller
            name="zipCode"
            control={control}
            rules={{
              required,
              validate: validateZipCode,
            }}
            render={({ field, fieldState: { error } }) => (
              <Wrapper
                id="zip-code"
                label="Zip Code"
                className="w-1/6"
                showErrorText={false}
                required={required}
                error={!isNil(error)}
              >
                <ZipCodeTextField
                  {...field}
                  disabled={disabled}
                  onBlur={handleOnBlur(field)}
                />
              </Wrapper>
            )}
          />
        </div>
      </Accordion>
    </div>
  );
}
