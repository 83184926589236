import React, {
  RefObject,
  SyntheticEvent,
  useCallback,
} from 'react';
import clsx from 'clsx';
import Autocomplete, {
  AutocompleteProps,
} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {
  InputProps as StandardInputProps,
} from '@mui/material/Input/Input';
import SearchIcon from 'core/icons/search';

export type Props<T> = Pick<
    AutocompleteProps<T, boolean, boolean, boolean>,
    'className'
    | 'value'
    | 'defaultValue'
    | 'options'
    | 'renderOption'
    | 'renderTags'
    | 'ListboxComponent'
    | 'ListboxProps'
    | 'disabled'
    | 'multiple'
    | 'autoComplete'
    | 'loading'
    | 'loadingText'
    | 'openOnFocus'
    | 'placeholder'
    | 'includeInputInList'
    | 'filterSelectedOptions'
    | 'noOptionsText'
    | 'blurOnSelect'
    | 'popupIcon'
    | 'freeSolo'
    | 'open'
    | 'clearOnBlur'
    | 'disablePortal'
    | 'onBlur'
    | 'onFocus'
    | 'onOpen'
    | 'onClose'
    | 'onInputChange'
    | 'filterOptions'
    | 'isOptionEqualToValue'
    | 'selectOnFocus'
    | 'classes'
    | 'inputValue'
    | 'groupBy'
    | 'renderGroup'
    | 'clearIcon'
  >
  & {
  id?: string;
  label?: string;
  required?: boolean;
  getLabel: (option: T) => string;
  inputRef?: RefObject<any>;
  readonly?: boolean;
  dropdownClassName?: string;
  InputProps?: Partial<StandardInputProps>;
  onChange: (value: T | T[]) => void;
  disableRemove?: boolean;
  getOptionDisabled?: (option: T) => boolean;
};

export default function AutoCompletedInput<T>({
  id,
  className,
  label,
  required,
  getLabel,
  inputRef,
  classes,
  readonly,
  dropdownClassName,
  InputProps = {},
  onChange,
  options = [],
  filterSelectedOptions = true,
  autoComplete = false,
  includeInputInList = false,
  loading = false,
  clearOnBlur = true,
  openOnFocus = false,
  loadingText = 'Loading...',
  noOptionsText = 'No Options',
  renderTags: _renderTags,
  popupIcon,
  value,
  placeholder,
  disabled,
  disableRemove = false,
  getOptionDisabled,
  clearIcon = null,
  ...props
}: Props<T>) {
  const autoCompleteClasses = clsx('populate-autocomplete', className, { disabled });
  const handleChange = (event: SyntheticEvent, value: any, reason: string) => {
    // when values are handled and displayed outside of mui input,
    // disable removing with backspace | delete
    if (reason !== 'removeOption' || !disableRemove) {
      onChange(value);
    }
  };

  const renderTags = _renderTags ?? useCallback((value: T[]) => (
    value.map((v: T) => (
      <span
        key={getLabel(v)}
        className="mr-1"
      >
        {getLabel(v)}
      </span>
    ))
  ), [getLabel]);

  const icon = typeof popupIcon === 'undefined' ? <SearchIcon className="search-icon" /> : popupIcon;
  return (
    <Autocomplete
      {...props}
      id={id}
      disabled={disabled}
      className={autoCompleteClasses}
      clearIcon={clearIcon}
      placeholder={placeholder}
      onChange={handleChange}
      getOptionDisabled={getOptionDisabled}
      value={value}
      options={options}
      getOptionLabel={(option) => getLabel(option as T)}
      popupIcon={!readonly && icon}
      renderTags={renderTags}
      loadingText={loadingText}
      noOptionsText={noOptionsText}
      classes={classes}
      readOnly={readonly}
      slotProps={{ paper: { className: dropdownClassName ?? '' } }}
      clearOnBlur={clearOnBlur}
      openOnFocus={openOnFocus}
      filterSelectedOptions={filterSelectedOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          id={id}
          value={value}
          inputRef={inputRef}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            ...InputProps,
          }}
        />
      )}
    />
  );
}
