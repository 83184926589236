import {
  normalizeFilterableSettings,
} from 'core/Filters/util';
import {
  AddendumDTO,
  ConsentFormInfoDTO,
  ConsentFormsCommuniqueDTO,
  None,
  PatientDTO,
  PatientVisitDTO,
  SettingsDTO,
} from 'dtos';
import {
  patchApiPatientUpdatePatient,
  postApiPatientAddUpdateOrDeletePatientInsurances,
  getApiVisitNoteGetHistoricalVisitsByPatientIdPatientId,
  getApiVisitNoteGetAddendumFinalizedVisitNoteId,
  getApiVisitNoteGetUpcomingAppointmentsByPatientIdPatientId,
  putApiVisitNoteAddAddendum,
  getApiSettingsGetPatientMultimediaFilterSettings,
} from 'endpoints';
import usePrefetchedData from 'pages/Dashboard/hooks/usePrefetchedData';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from 'react-query';
import {
  getConsentFormsByPatientId,
  getPatientInfo,
  sendConsentForms,
} from 'pages/Dashboard/pages/Charts/pages/Details/services/api';
import {
  PatientForm,
} from 'pages/Dashboard/types/patient';
import {
  serializePatient,
} from 'pages/Dashboard/pages/Charts/pages/Details/utils/helper';
import {
  isEmptyNumber,
} from 'utils/misc';

// consentForms
export function useGetConsentFormsByPatient(
  patientId: number,
  enabled: boolean = true,
): UseQueryResult<ConsentFormInfoDTO[], Error> {
  return useQuery<ConsentFormInfoDTO[], Error>(
    `consent-forms-${patientId}`,
    () => getConsentFormsByPatientId(patientId),
    { enabled },
  );
}

export function useSendConsentForms(): UseMutationResult<
  void, Error, ConsentFormsCommuniqueDTO> {
  return useMutation<void, Error, ConsentFormsCommuniqueDTO>(sendConsentForms);
}

// patient charts
export function useUpdatePatientChart(isInsuranceSection = false):
  UseMutationResult<PatientDTO, Error, Partial<PatientForm>> {
  return useMutation<PatientDTO, Error, Partial<PatientForm>>({
    mutationFn: async (data: Partial<PatientForm>) => {
      const serialized = serializePatient(data);
      return isInsuranceSection
        ? postApiPatientAddUpdateOrDeletePatientInsurances(serialized)
        : patchApiPatientUpdatePatient(serialized);
    },
  });
}

export function useGetPatientInfo(id: number): UseQueryResult<PatientForm, Error> {
  return useQuery<PatientForm, Error>(
    ['patient', id],
    () => getPatientInfo(id),
    { enabled: !isEmptyNumber(id) },
  );
}

// visits
export const useGetUpcomingAppointments = (id: number = 0): UseQueryResult<
  PatientVisitDTO[], Error> => (
  useQuery<PatientVisitDTO[], Error>(
    ['upcomingVisits', id],
    () => getApiVisitNoteGetUpcomingAppointmentsByPatientIdPatientId(id),
    { enabled: id > 0 },
  )
);

export const useGetHistoricalVisitNotesByPatientId = (id: number = 0): UseQueryResult<
  PatientVisitDTO[], Error> => (
  useQuery<PatientVisitDTO[], Error>(
    ['pastVisits', id],
    () => getApiVisitNoteGetHistoricalVisitsByPatientIdPatientId(id),
    { enabled: id > 0 },
  )
);

// addendum
export function useGetAddendum(visitNoteId: number): UseQueryResult<AddendumDTO, Error> {
  return useQuery<AddendumDTO, Error>(
    ['addendum', visitNoteId],
    () => getApiVisitNoteGetAddendumFinalizedVisitNoteId(visitNoteId),
  );
}

export function useUpsertAddendum(): UseMutationResult<None, Error, AddendumDTO> {
  return useMutation<None, Error, AddendumDTO>(putApiVisitNoteAddAddendum);
}

// patientMultimedia
export function useGetPatientMultimediaFilterMetaComponents() {
  const { data } = usePrefetchedData<SettingsDTO>({
    key: 'patient-multimedia-filter-settings',
    fetchFn: getApiSettingsGetPatientMultimediaFilterSettings,
  });

  return normalizeFilterableSettings(data?.filterableSettings ?? []);
}
