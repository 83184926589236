import {
  request,
} from 'services/api';
import {
  Patient,
  PatientForm,
} from 'pages/Dashboard/types/patient';
import {
  transformPatient,
} from 'pages/Dashboard/pages/Charts/pages/Details/utils/helper';
import {
  AddendumDTO,
  ConsentFormsCommuniqueDTO,
  ConsentFormInfoDTO,
} from 'dtos';
import {
  sendRequest,
} from 'utils/httpClient';
import {
  getGeoCodeToAddress,
} from 'utils/misc';
import isNil from 'lodash/isNil';
import {
  GeocoderResult,
} from 'pages/Dashboard/pages/Charts/pages/Details/types';
import {
  googleMapKey,
} from 'env';

export function getConsentFormsByPatientId(patientId: number): Promise<ConsentFormInfoDTO[]> {
  return request<ConsentFormInfoDTO[]>(
    `/Communication/GetProceduralConsentFormsInfoByPatientId/${patientId}`,
    { method: 'GET' },
  );
}

export function sendConsentForms(data: ConsentFormsCommuniqueDTO): Promise<void> {
  return request<void>('/Communication/SendConsentForms', { method: 'POST', data });
}

export const getPatientInfo = (patientId: number): Promise<PatientForm> => (
  request<Patient>(`/patient/getPatientById/${patientId}`, { method: 'GET' })
    .then(transformPatient)
);

export const getAddendum = (visitNoteId: number): Promise<AddendumDTO> => (
  request<AddendumDTO>(`/visitNote/getAddendum/${visitNoteId}`, {
    method: 'GET',
  })
);

export type UpsertAddendumPayload = Pick<AddendumDTO, 'visitNoteBaseId' | 'content'>;
export const upsertAddendum = (data: UpsertAddendumPayload): Promise<void> => (
  request<void>('/visitNote/addAddendum', {
    method: 'PUT',
    data,
  })
);

export const getGeoCodeByAddress = async (address: string) => {
  const data = await sendRequest<GeocoderResult>(
    {
      url: 'https://maps.googleapis.com/maps/api/geocode/json',
      params: {
        address,
        key: googleMapKey,
      },
    },
    {},
    { isExternal: true },
  );
  if (/ok/i.test(data.status) && isNil(data.error_message)) {
    const {
      address_components: addressComponents,
      formatted_address: formattedAddress,
    } = data.results[0] ?? {};
    return getGeoCodeToAddress(addressComponents ?? [], formattedAddress);
  }
  const message = data.error_message ?? '';
  const customError = {
    message,
    status: 400,
    response: {
      data: {
        message,
      },
    },
  };
  throw customError;
};
