import {
  useEffect,
  useRef,
} from 'react';
import {
  useQuery,
  UseQueryResult,
} from 'react-query';
import {
  isEmptyString,
} from 'utils/misc';
import {
  AddressDTO,
} from 'dtos';
import isNil from 'lodash/isNil';
import {
  getGeoCodeByAddress,
} from 'pages/Dashboard/pages/Charts/pages/Details/services/api';

export function useGoogleMapGeoCode(address: string): UseQueryResult<AddressDTO, string> {
  return useQuery<AddressDTO, string>(
    ['postalCode', address],
    () => getGeoCodeByAddress(address),
    { enabled: !isEmptyString(address) },
  );
}

export const useGetGooglePlaces = (input: string) => {
  const autocompleteService = useRef<google.maps.places.AutocompleteService | null>(null);

  useEffect(() => {
    if (isNil(autocompleteService.current) && !isNil(window?.google)) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
  }, []);

  return useQuery(
    ['places', input],
    () => {
      if (isNil(autocompleteService.current)) {
        throw new Error('Autocomplete service not initialized');
      }

      return new Promise<google.maps.places.AutocompletePrediction[]>((resolve, reject) => {
        autocompleteService?.current?.getPlacePredictions({
          input,
          componentRestrictions: { country: 'us' },
        }, (results, status) => {
          if (/ok/i.test(status) && !isNil(results)) {
            resolve(results);
          } else {
            reject(new Error(status));
          }
        });
      });
    },
    {
      enabled: !isEmptyString(input),
    },
  );
};
