import React, {
  useMemo,
} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import compact from 'lodash/compact';
import {
  US_STATES,
  USStateType,
} from 'core/constants';
import AutoComplete from 'core/components/AutoComplete';

const stateOptions = cloneDeep(US_STATES);

type Props = {
  onChange: (...event: any[]) => void;
  onBlur: () => void;
  value?: string | null;
  className?: string;
};

export default function USStateSelect({
  onChange,
  onBlur,
  value,
  className,
}: Props) {
  const selectedState = useMemo<USStateType | null>(() => US_STATES.find(
    (region) => (
      region.name === value?.trim()
      || region.abbreviation === value?.trim()
    ),
  ) ?? null, [value]);

  const onStateValueChange = (val: USStateType | USStateType[]) => {
    const value = (val as USStateType)?.abbreviation ?? '';
    onChange(value);
  };

  return (
    <AutoComplete<USStateType>
      onChange={onStateValueChange}
      onBlur={onBlur}
      value={selectedState}
      className={className}
      placeholder="State"
      id="state"
      options={stateOptions}
      getLabel={(region) => compact([region.abbreviation, region.name]).join(' - ')}
      isOptionEqualToValue={(a, b) => a.name === b.name || a.abbreviation === b.abbreviation}
      classes={{
        inputRoot: '!pr-2 !py-2 !truncate',
        input: '!mr-4',
      }}
    />
  );
}
