import {
  FieldValues,
} from 'react-hook-form';
import transform from 'lodash/transform';
import keyBy from 'lodash/keyBy';
import {
  DateTimeOffsetRangeDTO,
  DecimalNullableRangeFilterDTO,
  FilterLookupTranslatorDTO,
  FilterableOptionDTO,
  FilterableSettingDTO,
} from 'dtos';

import {
  filterMap,
} from 'core/Filters/attributes';
import {
  FilterableSettings,
  FilterComponentsType,
  FilterLookupTranslator,
  FilterObjectType,
} from 'core/Filters/types';

import {
  LookupOptionType,
} from 'core/components/Lookup';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import dayjs from 'utils/dayjs';
import {
  formatISODateTime,
} from 'utils/date';
import omitBy from 'lodash/omitBy';

export function isFilterEmpty<T>(filterValues?: T) {
  return isEmpty(filterValues)
   || values(filterValues).every(
     (filterValue) => isEmpty(filterValue)
      || (isObject(filterValue) && values(filterValue).every(isNil)),
   );
}

const defaultFilterValuesByType: Record<
  FilterComponentsType,
  DecimalNullableRangeFilterDTO | string[] | DateTimeOffsetRangeDTO | string> = {
    range: {
      min: null,
      max: null,
    },
    multiselect: [],
    lookup: [],
    daterange: {
      start: undefined,
      end: undefined,
    },
    date: '',
    month: '',
    select: '',
  };

export const getFilterDefaultValues = (
  settings: Record<string, any>,
  pageObject: FilterObjectType,
) => ({
  filterDefault: settings[`${pageObject}Filters`] ?? {},
});

const serializeRange = (value: DecimalNullableRangeFilterDTO) => ({
  min: isNil(value?.min) ? null : Number(value?.min),
  max: isNil(value?.max) ? null : Number(value?.max),
});

const serializeLookup = (
  values: LookupOptionType[],
  lookupTranslator?: FilterLookupTranslator,
) => {
  const { idField } = lookupTranslator ?? {};
  return (values ?? []).map((option) => option?.[idField ?? ''] ?? undefined);
};

const serializeMultiselect = (value: string[] | number[]) => value;
const serializeDateRange = ([start, end]: Date[]) => {
  const [min, max] = [
    dayjs(start).startOf('day'),
    dayjs(end).endOf('day'),
  ].map((date) => formatISODateTime(date));

  return { min, max };
};

const serializeDate = (date: string) => formatISODateTime(dayjs(date).startOf('day'));
const serializeMonth = (date: string) => formatISODateTime(dayjs(date).startOf('month').startOf('day'));
const serializeSelect = (option: FilterableOptionDTO) => option?.value;

const serializeFunctionByType: Record<FilterComponentsType, (...values: any) => any> = {
  range: serializeRange,
  lookup: serializeLookup,
  multiselect: serializeMultiselect,
  daterange: serializeDateRange,
  date: serializeDate,
  month: serializeMonth,
  select: serializeSelect,
};

export const serializeFilterQuery = (
  values: FieldValues,
  metadataById: Record<string, FilterableSettingDTO>,
) => transform(
  values,
  (
    result,
    value,
    key,
  ) => {
    const lookupTranslator = metadataById[key]?.lookupTranslator ?? {};
    const type = (metadataById[key]?.filterType ?? '') as FilterComponentsType;
    result[key] = serializeFunctionByType[type]?.(value, lookupTranslator);
  },
{} as FieldValues,
);

export const filterComponentsByType = (objectType: FilterObjectType) => {
  const { getComponents } = filterMap[objectType];
  const components = getComponents();
  const componentsByName = keyBy(components, 'name');
  return { components, componentsByName };
};

export const getFilterInitialValues = (
  values: FieldValues,
  metadataById: Record<string, FilterableSettingDTO>,
) => transform(values, (result, value, key) => {
  result[key] = defaultFilterValuesByType[
      metadataById[key]?.filterType as FilterComponentsType];
}, {} as FieldValues);

export const normalizeFilterableSettings = (
  filterableSettings: FilterableSettings[],
) => filterableSettings.map(
  (setting) => (
    {
      ...setting,
      name: setting.dtoName,
      lookupTranslator: omitBy(setting.lookupTranslator as FilterLookupTranslatorDTO, isNil),
    }
  ),
);
