import React, {
  PropsWithChildren,
  useEffect,
} from 'react';
import clsx from 'clsx';
import {
  useFormContext,
} from 'react-hook-form';
import {
  ErrorMessage,
} from '@hookform/error-message';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import ToolTip from 'core/components/Tooltip';

function ErrorMessageList() {
  const { trigger, formState: { isValid, errors } } = useFormContext();
  useEffect(() => {
    if (!isValid || isEmpty(errors)) {
      trigger();
    }
  }, [isValid, errors]);

  return (
    <div className="flex flex-col gap-1 text-xs">
      <div className="font-semibold">Validation Failed</div>
      <div className="flex flex-col gap-1">
        {keys(errors).map((key) => (
          <div className="w-full">
            <ErrorMessage
              key={key}
              name={key}
              errors={errors}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default function DisabledFormTooltip({ children }: PropsWithChildren) {
  const { formState: { isValid } } = useFormContext();

  return (
    <ToolTip
      title={<ErrorMessageList />}
      hide={isValid}
      disableFocusListener={false}
    >
      <div className={clsx('cursor-pointer', { '!cursor-not-allowed': !isValid })}>
        {children}
      </div>
    </ToolTip>
  );
}
