import compact from 'lodash/compact';
import forIn from 'lodash/forIn';
import fromPairs from 'lodash/fromPairs';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import sortBy from 'lodash/sortBy';
import isNil from 'lodash/isNil';
import keys from 'lodash/keys';
import {
  Patient,
  ReviewOfSystemElement,
} from 'pages/Dashboard/types/patient';
import {
  BillingState,
  VisitNote,
} from 'pages/Dashboard/pages/Encounters/pages/VisitNote/types/visitNote';
import {
  VisitNotePageResponseDTO,
  VisitNoteDTO,
  PatientMedicalBackgroundResponseDTO,
  MedicationBaseDTO,
  MedicalHistoryDTO,
  SurgicalHistoryDTO,
  FamilyHistoryDTO,
  NonDrugAllergyDTO,
  VisitNoteBillingCodeDTO,
  BillingCodeDTO,
  VisitNoteBillingCodeLinkedICDCodeDTO,
  VisitNoteBillingAssessmentCodeDTO,
  VisitNoteBillingCodeModifierDTO,
} from 'dtos';
import {
  isEmptyNumber,
  isEmptyString,
  notEmptyString,
  setDefaultValueIf,
} from 'utils/misc';
import {
  FieldValues,
  UseFormSetValue,
} from 'react-hook-form';
import {
  normalizeBillingCodes,
} from 'pages/Dashboard/pages/Billing/helpers';

import {
  FilterOptionsState,
} from '@mui/material/useAutocomplete';
import {
  ServiceCode,
  ServiceType,
  VisitNoteBillingCode,
  VisitNoteServiceCode,
} from 'views/EMR/components/ServiceLookup/type';
import values from 'lodash/values';
import {
  chartData,
} from 'core/constants';
import {
  PatientMedicalBackground,
} from 'pages/Dashboard/pages/Encounters/pages/VisitNote/hooks';
import {
  normalizeContent,
  serializeContent,
} from 'views/EMR/Settings/helper';
import {
  VisitNoteData,
} from 'pages/Dashboard/pages/Encounters/pages/VisitNote/components/MainContent';
import {
  Payer,
} from 'pages/Dashboard/types/insurance';

export type ReviewOfSystemElementMap = Record<string, ReviewOfSystemElement>;

type MedicalBackgroundList = Partial<
  MedicationBaseDTO
  & MedicalHistoryDTO
  & SurgicalHistoryDTO
  & FamilyHistoryDTO
  & NonDrugAllergyDTO
>[];

const medicalBackgroundListKeys = [
  'medications',
  'drugAllergies',
  'nonDrugAllergies',
  'medicalHistories',
  'surgicalHistories',
  'familyHistories',
] as const;
type MedicalBackgroundListKey = typeof medicalBackgroundListKeys[number];

const billingCodeFields = [
  'code',
  'description',
  'numberOfUnits',
  'modifiers',
  'linkedICDCodes',
  'serviceCodeDetails',
  'visitNoteBillingCodeId',
];

const accessorsByListName: Record<MedicalBackgroundListKey, string> = {
  medications: 'displayName',
  drugAllergies: 'displayName',
  nonDrugAllergies: 'source',
  medicalHistories: 'conditionName',
  surgicalHistories: 'surgeryName',
  familyHistories: 'diseaseName',
};

export const visitNoteTextFields = [
  'plan',
  'physicalExam',
  'additionalHPI',
  'additionalAssessment',
  'radiology',
];

export function calculateBMI(height: number | null, weight: number | null) {
  return !isNil(height) && !isNil(weight)
    ? (703 * weight) / (height ** 2)
    : 0;
}

function getNormalizedList(
  data: MedicalBackgroundList,
  accessor: string,
) {
  return isEmpty(data)
    ? ['None']
    : compact(map(data, accessor)) ?? ['None'];
}

export function normalizePatientMedicalBackground({
  reviewOfSystem,
  height = 0,
  weight = 0,
  ...data
}: PatientMedicalBackgroundResponseDTO): PatientMedicalBackground {
  const reviewOfSystemElementsMap: ReviewOfSystemElementMap = {};
  reviewOfSystem?.reviewOfSystemElements?.forEach((element) => {
    const { title } = element;
    const discriminator = element.discriminator ?? '';
    if (isNil(reviewOfSystemElementsMap[discriminator])) {
      reviewOfSystemElementsMap[discriminator] = {
        ...omit(element, 'title'),
        title: discriminator,
        values: [],
      };
    }
    reviewOfSystemElementsMap[discriminator].values?.push(title ?? '');
  });

  const reviewOfSystemElements = values(reviewOfSystemElementsMap) ?? [];
  const bmi = calculateBMI(height, weight);

  const socialHistory = values(mapValues(
    pickBy(data.socialHistory, (value) => !isNil(value)),
    (value, key) => `${chartData[key].chartDetailLabel}: ${value}`,
  ));

  return {
    ...mapValues(
      accessorsByListName,
      (accessor, listName) => getNormalizedList(
        data?.[listName as MedicalBackgroundListKey] ?? [],
        accessor,
      ),
    ),
    socialHistory: isEmpty(socialHistory) ? ['None'] : socialHistory,
    bmi: [isEmptyNumber(bmi) ? 'None' : bmi.toFixed(2)],
    reviewOfSystem: reviewOfSystemElements,
  };
}

export const normalizeVisitNote = ({
  patient,
  visitNote,
}: VisitNotePageResponseDTO): VisitNote => {
  const _visitNote: VisitNote = {
    ...(visitNote as VisitNote),
    ...setDefaultValueIf(
      visitNote ?? {},
      '',
      isNil,
    ),
    finalizedVisitNoteMultiMediaId: visitNote?.finalizedVisitNoteMultiMediaId,
    patient: {
      ...patient!,
    } as Patient,
    ...(mapValues(pick(visitNote, visitNoteTextFields), normalizeContent)),
  };

  return _visitNote;
};

export function formatLinkedICDCodes(
  linkedICDCodes: VisitNoteBillingCodeLinkedICDCodeDTO[],
  orderIndexes: Record<string, number>,
  availableCodes: Set<string>,
) {
  return sortBy(
    linkedICDCodes ?? [],
    ({ code }) => orderIndexes[code ?? ''],
  ).filter(({ code }) => availableCodes.has(code ?? ''));
}

// @TODO: consolidate formatting
export const formatBillingCodesForVisit = (
  billingCodes: VisitNoteBillingCode[],
  assessmentCodes: VisitNoteBillingAssessmentCodeDTO[],
) => {
  const orderIndexes = fromPairs(assessmentCodes.map(({ code }, index) => ([code, index])));
  const availableCodes = new Set(compact(map(assessmentCodes, 'code')));

  return billingCodes
    .map((item: VisitNoteBillingCode) => {
      const _billingCode = pick(item, billingCodeFields) as VisitNoteBillingCode;

      return {
        ..._billingCode,
        linkedICDCodes: formatLinkedICDCodes(
          _billingCode.linkedICDCodes ?? [],
          orderIndexes,
          availableCodes,
        ),
        numberOfUnits: _billingCode.numberOfUnits ?? 1,
        modifiers: (_billingCode.modifiers ?? [])
          .filter((item: VisitNoteBillingCodeModifierDTO) => notEmptyString(item.code ?? ''))
          .map((item: VisitNoteBillingCodeModifierDTO) => ({
            ...item,
            description: item.description ?? item.code,
          })),
        serviceCodeDetails: {
          ...(_billingCode?.serviceCodeDetails ?? {}),
          drugIdentification: {
            ...(_billingCode?.serviceCodeDetails?.drugIdentification ?? {}),
            unitCode: _billingCode?.serviceCodeDetails?.drugIdentification?.unitCode?.code,
          },
        },
      };
    });
};

export const serializeVisitNote = ({ bill, ...visitNote }: VisitNoteData): VisitNoteDTO => ({
  ...visitNote,
  ...setDefaultValueIf(visitNote, null, isEmptyString),
  ...(mapValues(pick(visitNote, visitNoteTextFields), serializeContent)),
  bill: {
    ...(bill ?? {}),
    assessmentCodes: (bill?.assessmentCodes ?? []).map((code) => omit(code, ['keyId'])),
    serviceCodes: formatBillingCodesForVisit(
      (bill?.serviceCodes ?? []) as VisitNoteBillingCode[],
      bill?.assessmentCodes ?? [],
    ) as VisitNoteBillingCodeDTO[],
    customServiceCodes: bill?.customServiceCodes ?? [],
  },
});

export const defaultVisitNoteValues = {
  radiology: '',
  additionalHPI: '',
  physicalExam: '',
  plan: '',
  additionalAssessment: '',
  bill: {
    assessmentCodes: [],
    serviceCodes: [],
    customServiceCodes: [],
    state: BillingState.NotReady,
  },
};

export const billFields = ['bill.assessmentCodes', 'bill.serviceCodes', 'bill.customServiceCodes'];
export const visitNoteWithoutBillFields = [...keys(omit(defaultVisitNoteValues, ['bill']))];

export const visitNoteFields = [...visitNoteWithoutBillFields, ...billFields];

export const visitNoteFieldDirtyClasses = '!border-secondary !border-2';

export const populateVisitNoteForm = (
  visitNote: VisitNoteDTO,
  setValue: UseFormSetValue<FieldValues>,
) => {
  const setFormValue = (value: string | BillingCodeDTO[], key: string) => {
    const defaultValue = Array.isArray(value) ? [] : '';
    setValue(key, value ?? defaultValue);
  };

  const bill = {
    assessmentCodes: visitNote?.bill?.assessmentCodes ?? [],
    serviceCodes: normalizeBillingCodes(visitNote?.bill?.serviceCodes ?? []),
    customServiceCodes: (visitNote?.bill?.customServiceCodes ?? []).map(
      ({ keyId, ...item }) => item,
    ),
  };

  forIn(pick(visitNote, visitNoteWithoutBillFields), (value, key) => {
    setFormValue(normalizeContent((value ?? '') as string) as string | BillingCodeDTO[], key);
  });

  forIn(bill, (value, key) => {
    if (value?.length > 0) {
      setFormValue(value, `bill.${key}`);
    }
  });
};

export function normalizeServiceCodes(
  data: ServiceCode[],
  type: ServiceType,
): VisitNoteServiceCode[] {
  return data?.map((service) => ({
    ...service,
    numberOfUnits: 1,
    ...(type === 'CPTCode' ? {
      modifiers: [],
      linkedICDCodes: [],
    } : {}),
  })) ?? [];
}

export function filterAutocompleteOptions<T>(
  options: T[],
  state: FilterOptionsState<T>,
  getSearchableTexts: (option: T) => string[],
) {
  const { inputValue } = state;
  const searchRegex = new RegExp(inputValue.replaceAll('\\', '\\\\'), 'i');
  return isEmptyString(inputValue)
    ? options
    : options.filter((option: T) => {
      const texts = getSearchableTexts(option);
      return texts.some((text) => searchRegex.test(text));
    });
}

export const getBillingCodeDefaultValue = (
  defaultValues: Partial<VisitNoteBillingCode> = {},
): VisitNoteBillingCode => ({
  ...defaultValues,
  code: '',
  description: '',
  modifiers: [],
  linkedICDCodes: [],
  numberOfUnits: 1,
});

export const getCustomServiceCodeDefaultValue = (
  defaultValues: Partial<VisitNoteBillingCode> = {},
): VisitNoteBillingCode => ({
  ...defaultValues,
  code: '',
  description: '',
  numberOfUnits: 1,
});

export function filterCodes(options: ServiceCode[], state: FilterOptionsState<ServiceCode>) {
  return filterAutocompleteOptions<ServiceCode>(
    options,
    state,
    (option) => ([
      `${option?.code} - ${option?.description ?? ''}`,
      option?.inclusionTerms ?? '',
    ]),
  );
}

export function filterCustomCodes(options: ServiceCode[], state: FilterOptionsState<ServiceCode>) {
  return filterAutocompleteOptions<ServiceCode>(
    options,
    state,
    (option) => ([`${option?.serviceName} - ${option?.description ?? ''}`]),
  );
}

export function filterInsurances(options: Payer[], state: FilterOptionsState<Payer>) {
  return filterAutocompleteOptions<Payer>(
    options,
    state,
    (option) => ([option?.payerName ?? '', ...(option?.searchTerms?.split(' ').filter(notEmptyString) ?? [])]),
  );
}
